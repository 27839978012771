import { STARTERS_GRADE } from './constant/grade'
import { timeConvertHHmm } from './date'
import _ from 'lodash'

export const API_TEMPLATE = {
  AUTH_LOGIN: 'api/auth/login',
  AUTH_LOGOUT: 'api/auth/logout',
  AUTH_REFRESH_TOKEN: 'api/auth/refresh-token',

  ASSIGN_GET_DEMO_PLAN: '/api/calendar/demo/plan',
  ASSIGN_UPDATE_DEMO_PLAN: '/api/calendar/demo/plan/update',

  COMMON_LOG_CHANGE: 'api/calendar/change-log',
  COMMON_UPLOAD_FILE: 'api/upload',
  COMMON_ZALO_NOTIFICATON_CREATE: 'api/notification',
  COMMON_ZALO_NOTIFICATON_FILTER: 'api/notification/filter',

  COMMON_DATE_MONTHLY_EXAM: 'api/calendar/class-regular/session/date-monthly-exam',

  COMMON_GET_LEVEL: 'api/calendar/level',
  COMMON_GET_GRADE: 'api/calendar/grade',
  COMMON_GET_DEVICE: 'api/calendar/device',
  COMMON_GET_LEVEL_BY_ID: 'api/calendar/level/id',
  COMMON_GET_GRADE_BY_ID: 'api/calendar/grade/id',
  COMMON_GET_PROVINCE: 'api/calendar/province',
  COMMON_GET_REGION: 'api/calendar/region',
  COMMON_GET_SUBJECT: 'api/calendar/subject',
  COMMON_GET_STUDY_PROGRAM: 'api/calendar/study-program',
  COMMON_GET_CRM_PACKAGE: 'api/calendar/crm-package',

  CLASS_DEMO_FILTER: 'api/calendar/demo/filter',
  CLASS_DEMO_GET_DETAIL_WITH_STUDENTS: 'api/calendar/demo/id-with-children',
  CLASS_DEMO_ADD_NOTE_STUDENT: 'api/calendar/demo/student/note',
  CLASS_DEMO_STUDENT_FILTER: 'api/calendar/demo/student/filter',

  CLASS_REGULAR_GET_DETAIL_WITH_STUDENTS: 'api/calendar/regular/id-with-children',

  CLASS_ADD_NOTE: 'api/calendar/class-regular/add-note',
  CLASS_ADD_STUDENT_REGULAR: 'api/calendar/class-regular/student/add',
  CLASS_ADD_STUDENT_NOTE: 'api/calendar/class-regular/student/note',
  CLASS_ARRANGE_CLASS_ASSSIGN: 'api/calendar/task/arrange-class/assign',
  CLASS_ASSIGN_COOR_MULTIPLE: 'api/calendar/task/arrange-class/assign-multiple',
  CLASS_ARRANGE_DETAIL: 'api/arrange-class/{id}',
  CLASS_ARRANGE_MESSAGE: 'api/arrange-class/arrange-message',
  CLASS_ASSIGN_MULTIPLE_BY_FILTER:
    'api/arrange-class/assign/multiple-user-by-filter',
  CLASS_ASSIGN_MULTIPLE_CLASS_REGULAR:
    '/api/calendar/task/arrange-class/assign-batch',
  CLASS_ASSIGN_MULTIPLE_CLASS_MONITOR:
    '/api/calendar/task/monitor-session/assign-batch',
  CLASS_CANCEL: 'api/calendar/class-regular/cancel',
  CLASS_REGULAR_DETAIL: 'api/calendar/class-regular/id',
  CLASS_CANCEL_STUDENT: '/api/calendar/class-regular/student/cancel',
  CLASS_CHANGE: '/api/calendar/class-regular/student/change',
  CLASS_CHANGE_CALL_STATUS: 'api/calendar/task/arrange-class/call-status',
  CLASS_CHANGE_CALL_STATUS_RESCHEDULE_DEMO: 'api/calendar/task/change_demo_schedule/update-call-status',
  CLASS_CHANGE_ORDER_STUDENT: 'api/order-data/update-user',
  CLASS_CREATE_REGULAR_SESSION: 'api/class/create-regular-session',
  CLASS_CURRENT_STUDENT_ORDER: 'api/class/student-account/current-consuming-order',
  CLASS_DETAIL: 'api/calendar/class-regular/id',
  CLASS_DETAIL_WITH_CHILDREN: 'api/calendar/class-regular/id-with-children',
  CLASS_DETAIL_CLASS: 'api/arrange-class',
  CLASS_REGULAR_TICKET_CREATE: 'api/calendar/task/arrange-class/change-student-class',
  CLASS_EXPORT_ARRANGE_CLASS: 'api/calendar-report/task-arrange-class',
  CLASS_REGULAR_ARRANGE_CLASS_DETAIL_BY_ID: 'api/calendar/task/arrange-class/detail/id',
  CLASS_REGULAR_ARRANGE_CLASS_BY_ID: 'api/calendar/task/arrange-class/id',
  CLASS_EXPORT_REGULAR_CLASS: 'api/calendar-report/class-regular',
  CLASS_EXTEND: 'api/class/student/extend-package',
  CLASS_EXTEND_STUDENT_DATA: 'api/order-package/extends/details',
  CLASS_FILTER: 'api/class/filter',
  CLASS_LIST_ORDER_BY_USERID_AND_STATUS:
    'api/node/order-data/last-active-order',
  CLASS_LIST_PACKAGE: 'api/calendar/crm-package',
  CLASS_LOG_CHANGE: 'api/class/change-log',
  CLASS_PENDING_STUDENT_FILTER: 'api/arrange-class/filter',
  CLASS_STUDENT_ACCOUNT: '/api/calendar/class-regular/student/list',
  CLASS_STUDENT_PROFILE: '/api/calendar/student-account',
  CLASS_STUDENT_TRANSACTIONS: '/api/calendar/student-account/transaction',
  CLASS_UPDATE_PENDING_STUDENT: '/api/user/student',
  CLASS_REGULAR_ARRANGE_CLASS_FILTER: '/api/calendar/task/arrange-class/filter',
  CLASS_REGULAR_ARRANGE_CLASS_ADD_NOTES: 'api/calendar/task/arrange-class/add-notes',
  CLASS_UPDATE_REMAIN_SESSION: 'api/order-data/update-remain-session',
  CLASS_UPDATE_ACCOUNT_CREDIT:
    'api/calendar/student-profile/set',
  CLASS_UPDATE_MAIN_SESSION_CREDIT:
    '/api/calendar/student-account/setMain',
    CLASS_UPDATE_BUSY_SESSION_CREDIT:
    '/api/calendar/student-account/setBusy',
  CLASS_UPDATE_CLASS_STUDENT_DETAIL: 'api/calendar/class-regular/change-start-time',
  CLASS_UPDATE_DETAIL: 'api/calendar/class-regular/edit',
  CLASS_UPDATE_SESSION: 'api/class/update-regular-session',
  CLASS_DEMO_DETAIL: 'api/calendar/demo/id',
  CLASS_REGULAR_LIST_STUDENTS: 'api/calendar/class-regular/student/list',
  CLASS_GET_STARTTIME_STUDENT: 'api/calendar/class-regular/student/start-time',
  CLASS_GET_LOG_SCHEDULE: 'api/calendar/group-history/filter',

  TEACHER_CLASSIFY_FILTER: 'api/calendar/teacher-classify/filter',
  CONFIG_GET_REGION: '/api/calendar/region',
  CONFIG_ADD_REGION: '/api/calendar/region',
  CONFIG_GET_REGION_DETAIL: '/api/calendar/region/id',
  CONFIG_UPDATE_REGION: '/api/calendar/region/update',
  CRM_ORDER_DETAIL: 'api/calendar/crm-order/id',

  CLASS_REQUEST_DETAIL: 'api/calendar/student-arrange-class-request/find-condition',
  CLASS_DEMO_CANCEL: 'api/calendar/demo/cancel/{id}',

  CONFIG_MAPPING_REGION: 'api/calendar/province/map-regions',
  CONFIG_MAPPING_PROVINCE: 'api/calendar/region/map-provinces',
  CONFIG_GET_MAPPING_REGION: 'api/calendar/region/find-province-region',
  CONFIG_GET_MAPPING_PROVINCE: 'api/calendar/province/find-province-region',
  CONFIG_DELETE_MAPPING_REGION_PROVINCE: 'api/calendar/province/delete-map',

  CONFIG_GET_PROVINCE: '/api/calendar/province',
  CONFIG_ADD_PROVINCE: '/api/calendar/province',
  CONFIG_GET_PROVINCE_DETAIL: '/api/calendar/province/id',
  CONFIG_UPDATE_PROVINCE: '/api/calendar/province/update',

  CONFIG_GET_SCHOOL: '/api/calendar/school',
  CONFIG_ADD_SCHOOL: '/api/calendar/school',
  CONFIG_GET_SCHOOL_DETAIL: '/api/calendar/school/id',
  CONFIG_UPDATE_SCHOOL: '/api/calendar/school/update',

  CONFIG_GET_GRADE: '/api/calendar/grade',
  CONFIG_ADD_GRADE: '/api/calendar/grade',
  CONFIG_GET_GRADE_DETAIL: '/api/calendar/grade/id',
  CONFIG_UPDATE_GRADE: '/api/calendar/grade/update',

  CONFIG_GET_LEVEL: '/api/calendar/level',
  CONFIG_ADD_LEVEL: '/api/calendar/level',
  CONFIG_GET_LEVEL_DETAIL: '/api/calendar/level/id',
  CONFIG_UPDATE_LEVEL: '/api/calendar/level/update',

  CONFIG_GET_DEVICE: '/api/calendar/device',
  CONFIG_ADD_DEVICE: '/api/calendar/device',
  CONFIG_GET_DEVICE_DETAIL: '/api/calendar/device/id',
  CONFIG_UPDATE_DEVICE: '/api/calendar/device/update',

  CONFIG_GET_SUBJECT: '/api/calendar/subject',
  CONFIG_ADD_SUBJECT: '/api/calendar/subject',
  CONFIG_GET_SUBJECT_DETAIL: '/api/calendar/subject/id',
  CONFIG_UPDATE_SUBJECT: '/api/calendar/subject/update',

  CONFIG_GET_STUDY_PROGRAM: '/api/calendar/study-program',
  CONFIG_ADD_STUDY_PROGRAM: '/api/calendar/study-program',
  CONFIG_GET_STUDY_PROGRAM_DETAIL: '/api/calendar/study-program',
  CONFIG_UPDATE_STUDY_PROGRAM: '/api/calendar/study-program',

  CONFIG_GET_CRM_PACKAGE: 'api/calendar/crm-package',
  CONFIG_GET_CRM_PACKAGE_BY_STUDY_PROGRAM_ID: 'api/calendar/crm-package-config/find-by-study-program-id',
  CONFIG_GET_CRM_PACKAGE_BY_ID: 'api/calendar/crm-package/id',
  CONFIG_ADD_CRM_PACKAGE: 'api/calendar/crm-package-config/update-list',
  CONFIG_DELETE_CRM_PACKAGE: 'api/calendar/crm-package-config/delete-list',

  CONFIG_ADD_TEACHER_CLASSIFY: 'api/calendar/teacher-classify/register',
  CONFIG_MAP_TEACHER_CLASSIFY: 'api/calendar/teacher-classify/detail/assign',
  CONFIG_DELETE_TEACHER_CLASSIFY_TEACHER: 'api/calendar/teacher-classify/detail/remove',
  CONFIG_GET_TEACHER_CLASSIFY: 'api/calendar/teacher-classify/filter',
  CONFIG_GET_DETAIL_TEACHER_CLASSIFY: 'api/calendar/teacher-classify/detail/by-classify-id',
  CONFIG_UPDATE_TUTOR_FILE: 'api/calendar/teacher-classify/update',

  CONFIG_CREATE_SALARY_GROUP: 'api/calendar/teacher-level/create',
  CONFIG_UPDATE_SALARY_GROUP: 'api/calendar/teacher-level/update',
  CONFIG_GET_LIST_SALARY_GROUP: 'api/calendar/teacher-level/active',
  CONFIG_ADD_TEACHER_TO_SALARY_GROUP: 'api/calendar/teacher/level-teacher/add',
  CONFIG_REMOVE_TEACHER_IN_SALARY_GROUP: 'api/calendar/teacher/level-teacher/remove',
  CONFIG_GET_TEACHER_IN_SALARY_GROUP: 'api/calendar/teacher/level-teacher/find-by-teacher-level-id',

  CONFIG_CREATE_BREACH_GROUP: 'api/calendar/violation-level/create',
  CONFIG_UPDATE_BREACH_GROUP: 'api/calendar/violation-level/update',
  CONFIG_GET_LIST_BREACH_GROUP: 'api/calendar/violation-level/active',
  CONFIG_ADD_TEACHER_TO_BREACH_GROUP: 'api/calendar/violation-level-teacher/add',
  CONFIG_REMOVE_TEACHER_IN_BREACH_GROUP: 'api/calendar/violation-level-teacher/remove',
  CONFIG_GET_TEACHER_IN_BREACH_GROUP: 'api/calendar/violation-level-teacher/find-by-violation-id',

  CONFIG_MAKE_UP_SESSION_NUMBER_FILTER: '/api/calendar/refund-config/filter',
  CONFIG_MAKE_UP_SESSION_NUMBER_CREATE: '/api/calendar/refund-config/create',
  CONFIG_MAKE_UP_SESSION_NUMBER_UPDATE: '/api/calendar/refund-config/update',

  CONFIG_CREATE_TEACHER_MANAGER: '/api/calendar/tm-group/create',
  CONFIG_UPDATE_TEACHER_MANAGER: '/api/calendar/tm-group/update',
  CONFIG_GET_LIST_TEACHER_MANAGER: '/api/calendar/tm-group/list',
  CONFIG_ADD_TEACHER_TO_TEACHER_MANAGER: '/api/calendar/tm-group/teacher/assign',
  CONFIG_REMOVE_TEACHER_IN_TEACHER_MANAGER: '/api/calendar/tm-group/teacher/remove',
  CONFIG_GET_TEACHER_IN_TEACHER_MANAGER: '/api/calendar/tm-group/teacher/by-tm-group-id',

  CONFIG_GET_LEARNING_MODEL: 'api/calendar/config-crm-products',
  CONFIG_GET_LEARNING_MODEL_DETAIL: 'api/calendar/config-crm-products',
  CONFIG_ADD_LEARNING_MODEL: 'api/calendar/config-crm-products',
  CONFIG_UPDATE_LEARNING_MODEL: 'api/calendar/config-crm-products',

  CONFIG_GET_LIST_POTENTIAL_POINTS: 'api/calendar/potential/field',
  CONFIG_GET_DETAIL_POTENTIAL_POINTS: 'api/calendar/potential/field/find-by-id',
  CONFIG_GET_HISTORY_POTENTIAL_POINTS: 'api/calendar/potential/field/audit',
  CONFIG_ACTIVE_STATUS_POTENTIAL_POINTS: 'api/calendar/potential/field/active',
  CONFIG_DEACTIVE_STATUS_POTENTIAL_POINTS: 'api/calendar/potential/field/de-active',
  CONFIG_UPDATE_POTENTIAL_POINTS: 'api/calendar/potential/field/update',

  COHORT_GET_ALL: 'api/user/cohorts',
  COHORT_ADD_STUDENT: 'api/user/cohorts/student/add',
  COHORT_REMOVE_STUDENT: 'api/user/cohorts/student/remove',
  COHORT_ADD_ALL_STUDENT: 'api/user/cohorts/student/add-by-filter',

  PROFILE_CHANGE_PASSWORD: 'api/user/profile/change-password',
  PROFILE_DETAIL: 'api/user/profile',
  PROFILE_EDIT: 'api/user/profile',
  PROFILE_UPDATE_CLASS_IN_ID: 'api/profile/update-class-in-id',

  PROVINCE_LIST: 'api/province',

  SESSION_ADD_NOTE: 'api/calendar/demo/add-note',
  SESSION_ADD_STUDENT: 'api/calendar/demo/student/add',
  SESSION_ADD_STUDENT_DEMO_FROM_RESCHEDULE: '/api/calendar/task/change_demo_schedule/add-student',
  SESSION_ADD_STUDENT_COMMENT: 'api/calendar/class-regular/session/student/note',
  SESSION_ASSIGN_TEACHER: 'api/calendar/demo/change-teacher',
  SESSION_CANCEL: 'api/calendar/demo/remove',
  SESSION_CANCEL_BACKUP_MULTIPLE_BY_TEACHER:
    'api/session/teacher/cancel-backup',
  SESSION_CANCEL_BACKUP_MULTIPLE_BY_MANAGER: 'api/session/cancel-backup',
  SESSION_CANCEL_TEACHER_SCHEDULE: 'api/calendar/class-teacher-schedule/cancel',
  SESSION_CANCEL_MULTIPLE_BY_TEACHER: 'api/session/teacher/cancel-multiple',
  SESSION_CANCEL_MULTIPLE_BY_MANAGER: 'api/session/cancel-multiple',
  SESSION_CLASS_DEMO_BY_STUDENT: 'api/session/class-demo/find-by-student',
  SESSION_CREATE_CLASS_DEMO: 'api/calendar/demo/create',
  SESSION_CREATE_CLASS_REGULAR: 'api/calendar/class-regular/create',
  SESSION_CREATE_REGULAR_SESSION: '/api/calendar/class-regular/session',
  SESSION_CREATE_REGULAR_SESSION_IN_CLASS: 'api/calendar/class-regular/create-make-up-session',
  SESSION_EXPORT_CLASS_DEMO: 'api/calendar-report/class-demo',
  SESSION_EXPORT_REGULAR: 'api/calendar-report/class-regular-session',
  SESSION_EXPORT_REGULAR_SUMMARY: '/api/calendar-report/teacher-schedule-summary',
  SESSION_EXOPORT_TEACHER_BOOK: 'api/calendar-report/teacher-schedule',
  SESSION_FILTER_CLASS: 'api/calendar/demo/filter',
  SESSION_FILTER_CLASS_PENDING: 'api/calendar/class-regular/filter',
  SESSION_FILTER_CLASS_REGULAR: 'api/calendar/class-regular/filter',
  SESSION_FILTER_CALENDAR_CLASS_REGULAR: 'api/calendar/class-regular/filter-by-program',
  SESSION_FILTER_DEMO: 'api/session/filter/slot-demo',
  SESSION_FILTER_REGULAR: 'api/calendar/class-regular/session/filter',
  SESSION_FILTER_HOMEWORK: 'api/calendar/class-regular/session/filter-home-work',
  SESSION_FILTER_TEACHER: 'api/session/filter/schedule-session',
  SESSION_FILTER_TEACHER_DEDUPLICATED: 'api/node/sessions/aggr-teacher-session',
  SESSION_FIND_TEACHER: 'api/session/find/schedule-session/open',
  SESSION_REGULAR_TOTAL_SUMMARY: 'api/node/sessions/summary-regular',
  SESSION_ITEM: 'api/calendar/class-regular/session/id',
  SESSION_ITEM_WITH_CHILDREN: 'api/calendar/class-regular/session/id-with-children',
  DETAIL_LAST_SESSION: "api/calendar/class-regular/session/{id}/last-session",
  SESSION_CODE_WITH_CHILDREN: 'api/calendar/class-regular/session/code-with-children',
  SESSION_LIST_BY_CLASS: 'api/session/list-lesson',
  SESSION_LIST_BY_DATE_STUDENT: 'api/node/sessions/list-by-date-and-studentId',
  SESSION_LIST_TEACHER_BY_ASSIGNED_RATE:
    'api/calendar/class-teacher-schedule/filter-by-rate',
  SESSION_OPEN: 'api/calendar/class-teacher-schedule/add-by-manager',
  SESSION_OPEN_BACKUP: 'api/session/open-backup',
  SESSION_OPEN_FOLLOW_PLAN: 'api/session/open-follow-plan',
  SESSION_REMOVE_STUDENT: 'api/calendar/demo/student/delete',
  SESSION_STUDENT_BY_PHONE: 'api/crm/children',
  SESSION_TEACHER_CONFIRM: 'api/session/teacher/confirm?id={id}',
  SESSION_TEACHER_DECLINE: 'api/session/teacher/decline?id={id}',
  SESSION_TEACHER_OPEN: 'api/calendar/class-teacher-schedule/add',
  SESSION_TEACHER_OPEN_BACKUP: 'api/session/teacher/open-backup',
  SESSION_TEACHER_CANCEL: 'api/session/cancel',
  SESSION_TEACHER_RESTORE: 'api/session/open/slot',
  SESSION_UPDATE: 'api/session/update-demo-session',
  SESSION_UPDATE_STUDENT: 'api/calendar/demo/student/update',
  SESSION_UPDATE_NOTE_STUDENT: 'api/calendar/demo/student/note',
  SESSION_STUDENT_IN_SESSION: 'api/calendar/class-regular/session/find-students',
  SESSION_DELETE_STUDENT: 'api/calendar/class-regular/session/remove',
  SESSION_MOVE_STUDENT: 'api/calendar/demo/student/move',
  SESSION_GET_PARENT_CHARACTERISTICS: '/api/calendar/demo',
  GET_DETAIL_LAST_EXAM_SESSION: "api/calendar/class-regular/session",

  SESSION_GET_HOME_WORK_STUDENT: 'api/calendar/student-exercise',
  GET_SESSION_NEAREST: 'api/calendar/class-regular/session/nearest',
  UPDATE_TIME_STATUS_EXERCISE: 'api/calendar/report-tutor-quality/student-exercise',
  GET_STUDENT_PROMOTION_CHECK: 'api/user/students',

  GET_IMPROVEMENT_STUDENT: 'api/user/detail/student',
  GET_STATUS_STUDENT_IN_SESSION: 'api/calendar/class-regular/session',

  STATISTICS_REPORT_FILTER: 'api/calendar/evaluate-session/filter',
  STATISTICS_REPORT_EXPORT: 'api/calendar-report/evaluate-session',
  QUARTERLY_EXAM_REPORT_EXPORT: 'api/calendar-report/quarterly-exam',
  GET_EVALUATE_SESSION_ID: 'api/calendar/evaluate-session/session-id',
  GET_STATISTICS_REPORT_DETAIL: 'api/calendar/evaluate-session/id',
  GET_SUGGEST_POINT_DETAIL: '/api/calendar/evaluate-session/scoring-criteria-homework/id',
  UPDATE_STATISTICS_REPORT_DETAIL: 'api/calendar/evaluate-session/update',
  GET_EVALUATE_BY_SESSION_CODE: 'api/calendar/evaluate-session/session-code',
  GET_LESSON_CONTENT_BY_SESSION_ID: 'api/calendar/evaluate-session/lesson-content',

  UPDATE_QUARTERLY_EXAM: 'api/calendar/evaluate-session/update-quarterly-exam',
  GET_QUARTERLY_EXAM: '/api/calendar/evaluate-session',
  
  QUALITY_CONTROL_SESSION_FILTER: 'api/calendar/quality-control-session/filter',
  QUALITY_CONTROL_SESSION_EXPORT_GGSHEET: 'api/calendar-report/quality-control-session',
  QUALITY_CONTROL_SYSTEM_EXPORT_GGSHEET: 'api/calendar-report/quality-control-session/daily',
  QUALITY_CONTROL_SESSION_ASSIGN_MULTI: 'api/calendar/quality-control-session/assign-multiple',
  QUALITY_CONTROL_SESSION_GET_DETAIL: 'api/calendar/quality-control-session',
  QUALITY_CONTROL_SESSION_UPDATE_DETAIL: 'api/calendar/quality-control-session',
  QUALITY_CONTROL_SESSION_SCORE: 'api/calendar/quality-control-session/scoring-criteria-video',

  QUALITY_CONTROL_SESSION_GET_OPTION: 'api/calendar/quality-control-session',
  QUALITY_CONTROL_SESSION_UPDATE_OPTION: 'api/calendar/quality-control-session/modify',
  QUALITY_CONTROL_SESSION_REMAINING_CLASSIFY: 'api/calendar/quality-control-session',

  SESSION_DATA_HOMEWORK: '/api/calendar/home-work/id',
  CREATE_SESSION_DATA_HOMEWORK: 'api/calendar/home-work/create-speaking-result',
  UPDATE_SESSION_DATA_HOMEWORK: 'api/calendar/home-work/update-speaking-result',
  FIND_SESSION_DATA_HOMEWORK: 'api/calendar/home-work/find-speaking-result',
  GET_SPEAKING_GRADE_SESSION_DATA_HOMEWORK: 'api/calendar/home-work/speaking-grading-criteria',

  MAKEUP_SESSION_ARRANGE_CLASS_ASSSIGN: 'api/calendar/task/arrange-make-up-session/assign',
  MAKEUP_SESSION_CHANGE_CALL_STATUS: 'api/calendar/task/arrange-make-up-session/call-status',
  MAKEUP_SESSION_COOR_MULTIPLE: 'api/calendar/task/arrange-make-up-session/assign-multiple',
  MAKEUP_SESSION_EXPORT: 'api/calendar-report/task-arrange-make-up-session',
  MAKEUP_SESSION_ASSIGN_MULTIPLE:'/api/calendar/task/arrange-make-up-session/assign-batch',
  MAKEUP_SESSION_ADD_NOTES:'/api/calendar/task/arrange-make-up-session/add-notes',

  GET_LINK_LOGIN_CLASS_IN_BY_SESSION_ID: 'api/calendar/class-regular/session/login-linked',
  ADD_COMMENT_SESSION: 'api/calendar/class-regular/session/note',
  EDIT_SESSION: 'api/calendar/class-regular/session/update',
  MONITOR_OF_SESSION: 'api/calendar/task/monitor-session/id',
  SESSION_LIST_SCHEDULE_TEACHER: 'api/calendar/class-teacher-schedule/filter', // api/calendar/teacher-classify/filter
  SESSION_LIST_LEARN_DATE: 'api/calendar/class-teacher-schedule/filter-start-time',
  SESSION_LIST_FIRST_SCHEDULE: 'api/calendar/class-teacher-schedule/filter-first-schedule-by-teacher',
  SESSION_LIST_SECOND_SCHEDULE: 'api/calendar/class-teacher-schedule/filter-other-schedule-by-teacher',
  SESSION_LIST_SCHEDULE_TEACHER_MULTIPLE_SESSION: 'api/calendar/class-teacher-schedule/filter-by-program',
  CREATE_CLASS_REGULAR_WITH_MULTI_SCHEDULES: 'api/calendar/class-regular/create-multi-schedules',
  LIST_TEACHER_SCHEDULE_MULTI_SESSIONS_PER_WEEK: 'api/calendar/class-teacher-schedule/filter-by-program', // api/calendar/teacher-classify/filter
  LIST_TICKET_MAKEUP_SESSION: 'api/calendar/task/arrange-make-up-session/filter',
  TICKET_MAKEUP_SESSION_DETAIL: 'api/calendar/task/arrange-make-up-session/id-with-children',
  CREATE_MAKEUP_SESSION: 'api/calendar/class-regular/session/create-arrange-session',
  ADD_STUDENT_MAKEUP_SESSION: 'api/calendar/class-regular/session/arrange-add',
  CLASS_TEACHER_LIST_SCHEDULE_TEACHER_BY_CLASSIFY: 'api/calendar/class-teacher-schedule/filter-by-classify',
  ADD_STUDENT_SESSION: 'api/calendar/class-regular/session/add',
  TICKET_ASSIGN: 'api/calendar/task/monitor-session/assign',
  TICKET_ASSIGN_BY_CLASS_DEMO_ID: 'api/calendar/task/monitor-session/assign-by-class-demo-id',
  TICKET_ASSIGN_MULTIPLE: 'api/ticket/assign-multiple',
  TICKET_ASSIGN_MULTIPLE_BY_FILTER: 'api/ticket/assign/multiple-user',
  TICKET_SELF_ASSIGN: 'api/ticket/self-assign',
  TICKET_FILTER: 'api/ticket/filter',
  TICKET_CANCEL: 'api/ticket/cancel',
  TICKET_DETAIL: '/api/calendar/task/monitor-session/id?id={id}',
  TICKET_EXPORT_FIRST_TIME:
    'api/google/ticket/export-ticket-take-care-student-first-time',
  TICKET_EXPORT_REPORT_RESULT:
    'api/google/ticket/export-ticket-report-learned-result',
  TICKET_EXPORT_PERIODICAL:
    'api/google/ticket/export-ticket-take-care-in-course',
  TICKET_EXPORT_BEFORE_EXPIRED:
    'api/google/ticket/export-ticket-take-care-before-expire',
  TICKET_EXPORT_TECHNICAL: 'api/google/ticket/export-ticket-support-technical',
  TICKET_FIND_TECHNICAL_BY_STUDENT_SESSION:
    'api/node/tickets/technical-ticket-by-student-and-session',
  TICKET_DIFFERENT_RESULT: 'api/node/tickets/result-compare',

  TICKET_DEMO_RESCHEDULE_ASSIGN: 'api/calendar/task/change_demo_schedule/update-employee-monitor',
  TICKET_DEMO_RESCHEDULE_UPDATE_EXPECT_LEARN_DATE: 'api/calendar/task/change_demo_schedule/update-estimated-expired-date',
  TICKET_DEMO_RESCHEDULE_ADD_NOTE: '/api/calendar/task/change_demo_schedule/add-notes',

  TICKET_MONITOR_FILTER: 'api/calendar/task/monitor-session/filter',
  TICKET_MONITOR_ASSIGN_MULTIPLE: 'api/calendar/task/monitor-session/assign-multiple',
  TICKET_MONITOR_ASSIGN_SELF: 'api/calendar/task/monitor-session/assign-self',
  GET_WARNING_EMPLOYEE_MONITOR: 'api/calendar/task/monitor-session/warning-employee-monitor',

  TICKET_RESCHEDULE_DEMO_FILTER: '/api/calendar/task/change_demo_schedule/filter',
  TICKET_RESCHEDULE_DEMO_DETAIL: '/api/calendar/task/change_demo_schedule/id',

  TICKET_LIST_REPORT_LEARNED_RESULT: 'api/ticket/list-report-learned-result',
  TICKET_UPDATE_AFTER_START: 'api/ticket/update-ticket-take-care-first-time',
  TICKET_UPDATE_BEFORE_EXPIRE:
    'api/ticket/update-ticket-take-care-before-expire',
  TICKET_UPDATE_FEEDBACK: 'api/ticket/update-ticket-feedback',
  TICKET_UPDATE_MONITOR: 'api/calendar/task/monitor-session/update',
  TICKET_UPDATE_REPORT_RESULT: 'api/ticket/update-ticket-report-result',
  TICKET_UPDATE_PERIODICAL: 'api/ticket/update-ticket-take-care-in-course',
  TICKET_UPDATE_TECHNICAL: 'api/ticket/update-ticket-technical',
  TICKET_UPDATE_TECHNICAL_TIME: 'api/ticket/update-ticket-technical-time',

  TICKET_SUPPORT_TECHNICAL_FILTER: 'api/calendar/task/support-technical/filter',
  TICKET_SUPPORT_TECHNICAL_CANCEL: 'api/calendar/task/support-technical/cancel',
  TICKET_SUPPORT_TECHNICAL_ASSIGN:
    'api/calendar/task/support-technical/assign',
  TICKET_SUPPORT_TECHNICAL_ASSIGN_SELF:
    'api/calendar/task/support-technical/assign-self',
  TICKET_SUPPORT_TECHNICAL_ASSIGN_MULTIPLE:
    'api/calendar/task/support-technical/assign-multiple',
  TICKET_SUPPORT_TECHNICAL_ASSIGN_BATCH:
    'api/calendar/task/support-technical/assign-batch',
  TICKET_SUPPORT_TECHNICAL_CREATE_MANUALLY:
    'api/calendar/task/support-technical/create/manually',
  TICKET_SUPPORT_TECHNICAL_UPDATE:
    'api/calendar/task/support-technical/update',
  TICKET_SUPPORT_TECHNICAL_DETAIL:
    'api/calendar/task/support-technical/id',
  TICKET_SUPPOR_TECHNICAL_REPORT:
    'api/calendar-report/task-support-technical',

  TEACHER_DETAIL_GET_BY_ID: 'api/calendar/tag-teacher/id',

  TIME_SEVER: 'api/calendar/system-time',
  
  FILTER_TUTOR_QUALITY_REPORT: '/api/calendar/report-tutor-quality/total-score',
  TUTOR_QUALITY_REPORT: 'api/calendar/report-tutor-quality/list',
  TUTOR_QUALITY_REPORT_ALL: 'api/calendar/report-tutor-quality/list-all',
  TUTOR_QUALITY_GET_DETAIL_TURN_ON_CAM: 'api/calendar/report-tutor-quality/filter',
  COUNT_TEACHER_TUTOR_QUALITY: 'api/calendar/report-tutor-quality/count-teacher',
  EXPORT_REPORT_TUTOR_QUALITY: 'api/calendar-report/report-tutor-quality',
  EXPORT_REPORT_TUTOR_QUALITY_TYPE: 'api/calendar-report/report-tutor-quality-type',
  EXPORT_UPDATE_POINT_ONLINE: 'api/calendar-report/report-tutor-quality/class-in',
  EXPORT_UPDATE_TRAINING_OFFLINE: 'api/calendar-report/report-tutor-quality/canvas',
  EXPORT_UPDATE_INSTRUCT_HOMEWORK: 'api/calendar-report/report-tutor-quality/student-exercise',

  TUTOR_QUALITY_REPORT_LIST_QCS: '/api/calendar/report-tutor-quality/list-qcs-date',
  TUTOR_QUALITY_REPORT_LIST_QCS_OUT_CLASS: '/api/calendar/report-tutor-quality/list-qcs-out-class',
  TUTOR_QUALITY_REPORT_LIST_QCS_WITH_TYPE: '/api/calendar/report-tutor-quality/list-qcs-type',
  UPDATE_POINT_QUALITY_REPORT: 'api/calendar/report-tutor-quality/score',

  TRACKING_METRIC_GET: 'api/calendar/tracking-metric',
  TRACKING_METRIC_GET_DETAIL: 'api/calendar/tracking-metric',
  TRACKING_METRIC_ADD: 'api/calendar/tracking-metric',
  TRACKING_METRIC_GET_CANVAS_COURSE: 'api/calendar/tracking-metric/canvas/course',
  TRACKING_METRIC_GET_MODULE: 'api/calendar/tracking-metric/canvas/course',
  TRACKING_METRIC_GET_QUIZ: 'api/calendar/tracking-metric/canvas/course',

  COMPONENT_SCORE_WEIGHTS_GET: 'api/calendar/qcs-weight-config',
  COMPONENT_SCORE_WEIGHTS_GET_DETAIL: 'api/calendar/qcs-weight-config/id',
  COMPONENT_SCORE_WEIGHTS_ADD: 'api/calendar/qcs-weight-config/update',
  QCS_WEIGHT_CONFIG_GET_WEIGHT: 'api/calendar/qcs-weight-config/get-weight',

  USER_CHANGE_PASSWORD: 'api/user/change-password',
  USER_CREATE: 'api/user',
  USER_CREATE_TEACHER: 'api/user/teacher',
  USER_CREATE_CUSTOMER_CARE: 'api/user/employ-customer-care',
  USER_CHECK_STUDENT: 'api/user/check',
  USER_DETAIL: 'api/user/refactor/detail-user?id={id}',
  USER_DETAIL_STUDENT: 'api/user/refactor/detail-student?id={id}',
  CHECK_NEW_STUDENT: 'api/user/detail/student/{id}/check-new',
  USER_EDIT: 'api/user',
  USER_EDIT_STUDENT: 'api/user/student',
  USER_EDIT_STUDENT_INFO: 'api/user/students/edit',
  USER_EDIT_TEACHER: 'api/user/teacher',
  USER_EDIT_TEACHER_PROFILE: 'api/user/teacher/edit-profile',
  USER_UPLOAD_FILE: 'api/user/image/upload',
  USER_DELETE_IMAGE_FILE: 'api/user/image',
  USER_EDIT_CUSTOMER_CARE: 'api/user/employ-customer-care',
  USER_EXPORT_STUDENT: 'api/calendar-report/student',
  USER_FIND_STUDENTS_BY_PHONE: 'api/user/refactor/find-student-by-phone',
  USER_FILTER: 'api/user/refactor/filter-user',
  USER_FILTER_STUDENT: 'api/user/refactor/filter-student',
  USER_FILTER_TEACHER: 'api/user/refactor/filter-teacher-without-limit',
  USER_FILTER_TEACHER_LIMIT: 'api/user/refactor/filter-teacher',
  USER_IMPORT_TEACHER: 'api/user/teacher-import',
  USER_EXPORT_TEACHER: 'api/calendar-report/teacher',
  USER_LAST_CLASS_STUDENT: '/api/calendar/student-account/last-class',
  USER_LIST: 'api/user/refactor/find-all-by-role',
  USER_ORDER_DATA: 'api/calendar/crm-order/find-by-crm-child-id',
  USER_ORDER_DATA_OLD_VERSION: '/api/calendar/crm-order/find-by-lms1-student-id',
  USER_ORDER_DATA_BY_ID: 'api/calendar/crm-order/id',
  USER_CRM_CHILD_ID: 'api/crm/children/get-crm3-id',
  USER_PERMISSION_ADD: 'api/permission/add',
  USER_PERMISSION_LIST: 'api/permission/list',
  USER_PERMISSION_REMOVE: 'api/permission/remove',
  USER_PROFILE: 'api/user/profile',
  USER_ROLE_DETAIL: 'api/permission/find?roleId={roleId}',
  USER_ROLE_LIST: 'api/user/permission/roles',
  USER_SEARCH_BY_USERNAME: 'api/user/filter-by-username',
  USER_SEARCH_TEACHER: 'api/user/search-teacher?keyword={keyword}',
  USER_SEARCH_USER: 'api/user/search-user?keyword={keyword}&role={role}',
  USER_TEACHER_BY_GRADE_AND_TYPE: '/api/user/list-teacher-to-choose',
  USER_SUSPEND: 'api/user/suspend',
  USER_UPDATE_STUDENT_PROFILE: 'api/user/update-student-profile',
  USER_UPDATE_CLASSIN_BROWSER_COOKIE: 'api/user/update-class-in-cookie',
  USER_DETAIL_TEACHER: 'api/user/refactor/detail-teacher?id={id}',
  TEACHER_SALARY: 'api/calendar/teacher-salary/find-by-salary',
  RANK_TEACHER_SALARY: 'api/calendar/salary-teacher-level/find-by-teacher-id',
  USER_OVERVIEW: 'api/user/students/overview',
  GET_STUDENT_INFO_BY_ID: 'api/user/students/detail',

  USER_EXPIRE_LIST_FILTER: 'api/user/student-remain/filter',
  USER_IMPORT_CUSTOMER_CARE_RESULTS: 'api/user/student-remain/parents-feedback-import',
  USER_EXPORT_CUSTOMER_CARE_RESULTS: 'api/calendar-report/student-remain',
  USER_DOWNLOAD_TEMPLATE: 'api/user/student-remain/download-template',
  USER_GET_DETAIL: 'api/user/student-remain',
  UPDATE_FEEDBACK_PARENT: 'api/user/student-remain/parents-feedback',
  GET_ADD_INFO_POTENTIAL_POINT: 'api/user/student-remain/parents-feedback-potential-details',
  UPDATE_CALL_STATUS_EXPIRE: 'api/user/student-remain/call-status',

  VIOLATION_LEVEL: 'api/calendar/violation-level/active',

  FILTER_CARE_STUDENT_LIST: 'api/calendar/task/after-school-care/filter',
  UPDATE_ASSIGN_CARE_CTV: 'api/calendar/task/after-school-care/assign-care-ctv',
  UPDATE_CHANGE_CARE_SPECIALIST: 'api/calendar/task/after-school-care/change-care-specialist',
  UPDATE_CARE_STATUS: 'api/calendar/task/after-school-care/update-care-status',
  UPDATE_CARE_RESULT: 'api/calendar/task/after-school-care/update-care-result',
  UPDATE_CARE_CALL_STATUS: 'api/calendar/task/after-school-care/update-call-status',
  CHECK_CAN_UPDATE_CARE_STUDENT: 'api/calendar/task/after-school-care/is-can-change',
  CHANGE_LOG_CARE_STUDENT: 'api/calendar/change-log/task-after-school-care',
  GET_AFTER_SCHOOL_CARE: 'api/calendar/task/after-school-care/id',
  STUDENT_CARE_COOR_MULTIPLE: 'api/calendar/task/after-school-care/assign-multiple',
  STUDENT_CARE_ASSIGN_BATCH: 'api/calendar/task/after-school-care/assign-batch',

  // API STUDENT_CARE V2
  FILTER_STUDENT_CARE: 'api/calendar/tasks/filter',
  UPDATE_CALL_STATUS_STUDENT_CARE: 'api/calendar/tasks/update-call-status',
  GET_STUDENT_CARE_GREETING_CALL_DETAIL: 'api/calendar/tasks/{id}/task-greeting-call',
  GET_STUDENT_CARE_TAKE_CARE_DETAIL: 'api/calendar/tasks/{id}/take-care',
  GET_OPINION_SCHEDULE_OPTION: 'api/calendar/tasks/opinion-schedule-list',
  GET_EXPECT_PARENT_OPTION: 'api/calendar/tasks/expect-parent-list',
  UPDATE_TASK_GREETING_CALL: 'api/calendar/tasks/{id}/update-greeting-call',
  FILTER_STUDENT_CARE_JOURNEY: 'api/calendar/tasks/filter-journey',
  FILTER_LOG_CHANGE_HISTORY: '/api/calendar/group-history/filter',
  
  //API CUSTOMER SURVEY
  FILTER_CUSTOMER_SURVEY: 'api/calendar/user-survey/filter',
  GET_DETAIL_CUSTOMER_SURVEY: 'api/calendar/user-survey/find-by-id',
  EXPORT_CUSTOMER_SURVEY: 'api/calendar-report/user-survey/export',
  IMPORT_CUSTOMER_SURVEY: 'api/calendar/user-survey/import',
  UPDATE_DETAIL_CUSTOMER_SURVEY: 'api/calendar/user-survey/update',


  EXPECT_PARENT: 'api/calendar/task/after-school-care/expect-parent-list',
  OPINION_SCHEDULE: 'api/calendar/task/after-school-care/opinion-schedule-list',
  UPDATE_GREETING_CALL: 'api/calendar/task/after-school-care/update-greeting-call',

  // STUDENT CARE PERIODIC
  STUDENT_CARE_PERIODIC_WEEKLY_EXERCISE: '/api/calendar/tasks/{id}/weekly-exercise',
  UPDATE_STUDENT_CARE_PERIODIC_WEEKLY_EXERCISE: '/api/calendar/tasks/{id}/weekly-exercise',
  STOP_CARE_PERIODIC_WEEKLY_EXERCISE: '/api/calendar/tasks/{id}/weekly-exercise/stop-take-care',

  UPDATE_CHANGE_HOMEROOM_TEACHER: 'api/user/care-specials/assign',
  FILTER_HOMEROOM_TEACHER_LIST: 'api/user/care-specials/filter',
  HOMEROOM_TEACHER_MULTIPLE: 'api/user/care-specials/assign-multiple',
  HOMEROOM_TEACHER_ASSIGN_BATCH: 'api/user/care-specials/assign-batch',
  
  USER_RESERVES_LIST: 'api/calendar/reservation-from/find-by-student-id',
  USER_RESERVES_CREATE: 'api/calendar/reservation-from/create',
  USER_RESERVES_EDIT: 'api/calendar/reservation-from/update',
  USER_RESERVES_CANCEL: 'api/calendar/reservation-from/cancel',
  DETAIL_PACKAGES_RESERVE: 'api/calendar/reservation-from/id-with-children',

  TRANSFER_FILTER: 'api/calendar/student-transfer/filter',
  TRANSFER_CREATE: 'api/calendar/student-transfer/create',
  TRANSFER_DETAIL: 'api/calendar/student-transfer/id-with-children',
  TRANSFER_DETAIL_NOTE: 'api/calendar/student-transfer/detail/notes',

  GET_MESSAGE_HISTORY_LIST_BY_STUDENT_ID: 'api/calendar/ccm/zalo/processors/histories',

  GET_ORDER_HAVE_CREDIT_BY_STUDENT_ID: 'api/calendar/student-account/student-order-have-credit',
  CREATE_PROGRAM_CONVERSION: 'api/calendar/students/program-conversion',
  GET_LIST_PROGRAM_CONVERSION: 'api/calendar/students/program-conversion',
  GET_DETAIL_PROGRAM_CONVERSION: 'api/calendar/students/program-conversion',
  CANCEL_PROGRAM_CONVERSION: 'api/calendar/students/program-conversion/cancel',

  GET_LEARNING_ATTITUDE: 'api/calendar-report/learning-attitude-follow-chart',
  GET_LEARNING_ATTITUDE_TABLE: 'api/calendar-report/learning-attitude-statistical-table-report',
  GET_LEARNING_ATTITUDE_SUMMARY_FOLLOW_CHART: 'api/calendar-report/learning-attitude-summary-follow-chart',
  GET_LEARNING_ATTITUDE_BY_MONTH: 'api/calendar-report/learning-attitude-by-month',

  GET_LEARNING_OUTCOMES: 'api/calendar-report/summary-of-test-score-result-chart',
  GET_LEARNING_OUTCOMES_TABLE: 'api/calendar-report/monthly-quarterly-test-score-statistics-table-report',
  GET_LEARNING_OUTCOMES_SUMMARY_FOLLOW_CHART: '/api/calendar-report/homework-score-tracking-report',

  GET_DILIGENCE_SUMMARY: 'api/calendar-report/summary-of-diligence-chart',
  GET_DILIGENCE: 'api/calendar-report/attendance-tracking-chart',
  GET_DILIGENCE_FOLLOW_STUDY: 'api/calendar-report/monthly-quarterly-exam-tracking-chart',
  GET_DILIGENCE_FOLLOW_EXAM: '/api/calendar-report/homework-score-tracking-report',
  GET_DILIGENCE_OVERVIEW_CHART: 'api/calendar-report/diligence-overview-chart',

  //Mức độ điểm tiềm năng
  GET_LIST_POTENTIAL_POINTS: 'api/calendar/potential/find-by-student-id',
  GET_HISTORY_POTENTIAL_POINTS: 'api/calendar/potential/find-by-student-and-month',
  GET_HISTORY_POTENTIAL_LEVEl_INTIMACY: 'api/calendar/potential/level-intimacy/history',
  GET_HISTORY_POTENTIAL_LEVEl_SATISFACTION: 'api/calendar/potential/level-satisfaction/history',
  GET_DETAIL_POTENTIAL_POINTS: 'api/calendar/potential/find-by-id',
  
  STUDY_PROGRAM_UPDATE: 'api/calendar/study-program/update',
  STUDY_PROGRAM_ADD: 'api/calendar/study-program/add',
  STUDY_PROGRAM_IDS: 'api/calendar/study-program/ids',
  STUDY_PROGRAM_FIND_ID: 'api/calendar/study-program/find/id',
  STUDY_PROGRAM_EXPORT: 'api/calendar/study-program/export',
  STUDY_PROGRAM_GET_BY_ID: 'api/calendar/study-program/id',
  STUDY_PROGRAM_FILTER: 'api/calendar/study-program/filter',
  STUDY_PROGRAM_GET_LIST: 'api/calendar/study-program',
  CLASS_STUDENT_STUDY_PROGRAM: 'api/calendar/study-program/id',
  CLASS_STUDENT_STUDY_PROGRAM_WITH_CHILDREN: '/api/calendar/task/arrange-class/id-with-children',

  CALENDAR_ADD_TIME_SLOT: 'api/calendar/class-time-slot/add',
  CALENDAR_TOTAL_SUMMARY: 'api/calendar/class-teacher-schedule/summary',
  CALENDAR_FIND_ALL_TIME_SLOT: 'api/calendar/class-time-slot/find-all-include-not-published',
  CALENDAR_FIND_ALL_TIME_SLOT_PUBLISHED:
    'api/calendar/class-time-slot/find-all',
  CALENDAR_CHANGE_STATUS_TIME_SLOT:
    'api/calendar/class-time-slot/change-status',
  CALENDAR_GET_CLASS_SCHEDULE_BY_SCHEDULE_TYPE:
    'api/calendar/class-schedule-slot/get-by-type',
  CALENDAR_ADD_CLASS_SCHEDULE_TIME_SLOT: 'api/calendar/class-schedule-slot/add',
  CALENDAR_HOUR_LEARNING: 'api/calendar/class-schedule-slot/get-by-type',
  CALENDAR_CLASS_TEACHER_SCHEDULE: 'api/calendar/class-teacher-schedule/filter',
  CALENDAR_HOUR_LEARNING_BY_TYPE: '/api/calendar/class-time-slot/find-by-schedule-type',
  CALENDAR_CLASS_TEACHER_SCHEDULE_CANCEL: '/api/calendar/demo/remove',
  CALENDAR_CLASS_CANCEL_SCHEDULE: 'api/calendar/task/teacher-schedule-urgent-cancel/update-status',
  CALENDAR_CLASS_CANCEL_SCHEDULE_FILTER: 'api/calendar/task/teacher-schedule-urgent-cancel/filter',
  CALENDAR_CLASS_TEACHER_URGENT_CANCEL: 'api/calendar/class-teacher-schedule/teacher-urgent-cancel',
  UPDATE_LIST_CANCEL_REGISTER_MULTIPLE: 'api/calendar/task/teacher-schedule-urgent-cancel/update-status-multiple-confirm',

  REVIEW_CLASS_DEMO_FILTER: 'api/calendar/task/review-demo/filter',
  REVIEW_CLASS_DEMO_DETAIL: 'api/calendar/task/review-demo/id',
  REVIEW_CLASS_DEMO_ASSIGN_BATCH: 'api/calendar/task/review-demo/assign-batch',
  REVIEW_CLASS_DEMO_UPDATE: 'api/calendar/demo/update-review-demo',

  FILTER_RECEIVE_BACKUP: 'api/calendar/teacher/backup-session-assign/list',
  RECEIVE_BACKUP: 'api/calendar/teacher/backup-session-assign/confirm',

  GET_REGISTER_DUTY_SCHEDULE: 'api/calendar/tasks/monitor-backup/plan',
  UPDATE_REGISTER_DUTY_SCHEDULE: 'api/calendar/tasks/monitor-backup/plan',
  FILTER_REGISTER_DUTY_SCHEDULE: 'api/calendar/tasks/monitor-backup/plan',
  FILTER_LIST_SLOT_DUTY_BACKUP: 'api/calendar/tasks/monitor-backup/filter',
  UPDATE_CALL_STATUS_BACKUP: 'api/calendar/tasks/monitor-backup/call-status',
  GET_TEACHER_BACKUP_OPTION: 'api/calendar/tasks/monitor-backup/teacher-option',
  GET_SESSION_BACKUP_OPTION: 'api/calendar/class-regular/session/option',
  UPDATE_SESSION_BACKUP: 'api/calendar/tasks/monitor-backup',
  UPDATE_CANCEL_MONITOR_BACKUP: 'api/calendar/teacher/absence/cancel-monitor-backup',
  EXPORT_GOOGLE_SHEET_MONITOR_BACKUP: 'api/calendar-report/task-monitor-backup',

  VIDEO_CONFERENCE_GET_RECORD_SESSION: '/api/video-conference/session',
  WAITING_CONFIRM_LIST: 'api/calendar/teacher/confirm-schedule/filter',
  EXPORT_WAITING_CONFIRM_CLASS: 'api/calendar-report/export/teacher-confirm-schedule',
  UPDATE_STATUS_WAITING_CONFIRM: 'api/calendar/teacher/confirm-schedule/update-status',
  UPDATE_STATUS_WAITING_CONFIRM_TEACHER_SUGGEST: 'api/calendar/teacher/confirm-schedule/teacher-suggest/update-status',
  UPDATE_STATUS_WAITING_MULTIPLE: 'api/calendar/teacher/confirm-schedule/send-confirm',
  WAITING_CONFIRM_CHECK_TEACHER_STATUS: '/api/calendar/teacher/confirm-schedule/check-status',
  SAVE_NOTE_CHANGE_TEACHER: '/api/calendar/teacher/confirm-schedule/save-note',

  SCHEDULE_ABSENCE_OF_TEACHER: '/api/calendar/teacher/absence/schedule/list',
  SUMMARY_ABSENCE_OF_TEACHER: '/api/calendar/teacher/absence/count',
  CREATE_SCHEDULE_ABSENCE: '/api/calendar/teacher/absence/create',
  UPDATE_SCHEDULE_ABSENCE: '/api/calendar/teacher/absence/explain',
  FILTER_LIST_ABSENCES: '/api/calendar/teacher/absence/list',
  GET_ABSENCE_DETAIL_BY_ID: '/api/calendar/teacher/absence/find-by-id',
  CANCEL_ABSENCE_SESSION: '/api/calendar/teacher/absence/cancel',
  UPDATE_ABSENCE_SESSION: '/api/calendar/teacher/absence/update',
  EXPORT_GOOGLE_SHEET_LIST_ABSENCE: '/api/calendar-report/teacher-absence-request',

  FILTER_LIST_CANCEL_HOMEROOM_CLASS: '/api/calendar/task-change-class-teacher/filter',
  EXPORT_GOOGLE_SHEET_LIST_CANCEL: '/api/calendar-report/task-change-class-teacher',
  GET_LIST_CANCEL_HOMEROOM_CLASS: '/api/calendar/task-change-class-teacher/class',
  CREATE_SCHEDULE_HOMEROOM_CLASS: '/api/calendar/task-change-class-teacher/create',
  UPDATE_SCHEDULE_HOMEROOM_CLASS: '/api/calendar/task-change-class-teacher/update',
  GET_LIST_REASON: '/api/calendar/task-change-class-teacher/reason',

  // SUPPORT QUIT JOB
  CREATE_SUPPORT_QUIT_JOB: '/api/calendar/teacher-contract-terminated/create',
  CONFIRM_SUPPORT_QUIT_JOB: '/api/calendar/teacher-contract-terminated/update',
  FILTER_LIST_REQUEST_QUIT_JOB: '/api/calendar/teacher-contract-terminated/filter',
  GET_DETAIL_QUIT_JOB: '/api/calendar/teacher-contract-terminated/id',
  GET_DATE_CONFIG_QUIT_JOB: '/api/calendar/teacher-contract-terminated/config',
  GET_BACK_TO_WORK_OPTIONS_QUIT_JOB: '/api/calendar/constant-config/type',
  EXPORT_GOOGLE_SHEET_LIST_QUIT_JOB: '/api/calendar-report/teacher-contract-terminated',
  EXPORT_GOOGLE_SHEET_CHANGE_TEACHER_QUIT_JOB: 'api/calendar-report/task-change-class-teacher-quit',

  // STUDENT CARE
  STUDENT_CARE_TICKET_DETAIL: '/api/calendar/tasks/{id}/take-care',
  ADD_NOTE_STUDENT_CARE_TICKET: '/api/calendar/tasks/{id}/notes',
  UPDATE_STUDENT_CARE_TICKET: '/api/calendar/tasks/{id}/take-care',

  // TAKE_CARE_STUDENT
  TAKE_CARE_STUDENT_TICKET_DETAIL: '/api/calendar/tasks/details',
  UPDATE_TAKE_CARE_STUDENT_TICKET_DETAIL: '/api/calendar/tasks/update-take-care-first-time',
  UPDATE_TAKE_CARE_STUDENT_SECOND_TICKET_DETAIL: '/api/calendar/tasks/update-take-care-second-time'
}

export const CATEGORY_TYPE = {
  GRADE: 'grades',
  DEVICE: 'devices',
  LEVEL: 'levels',
  PROVINCE: 'provinces',
  REGION: 'regions',
  SUBJECT: 'subjects',
  STUDY_PROGRAM: 'study_programs',
  CRM_PACKAGE: 'crm_packages',
  CLASS_TIME_SLOT: 'class_time_slots',
  VIOLATION_LEVEL: 'violation_level',
}

export const DEFAULT = {
  PAGE: 0,
  PAGE_SIZE: 20
}

export const Integer = {
  MAX_VALUE: 2147483647
}

export const ARRANGE_CLASS_MANUAL = ['CHANGE_CLASS', 'CHANGE_CLASS_IN_FUTURE']
export const GRADES_HAVE_WEEK_STUDYING = STARTERS_GRADE
export const ORDER_TYPE = ['NEW', 'CHANGE_CLASS', 'CHANGE_CLASS_IN_FUTURE', 'RESERVATION', 'STUDENT_TRANSFER', 'PROGRAM_CONVERSION']
export const REGULAR_SESSION_TYPE = [
  'FIRST_TIME',
  'LEARN_SCHEDULE',
  'MAKE_UP_LESSON'
]

export const WAITING_CONFIRM_TYPE = [
  'MAIN_CLASS',
  'SESSION_BACKUP',
  'MAKE_UP_LESSON',
  'MONITOR_BACKUP',
]

export const HOMEWORK = {
  quizExDTO: 'quizExDTO',
  speakingExDTO: 'speakingExDTO'
}

export const CLASS_IN_URL =
  'https://console.classin.com/saas/school/index.html#/SinglePage/CourseManagement/CourseLessonManagement'
export const URL_LEARN_LOG = 'https://crm.edupia.vn/admin/show-log-learn-v2'

export const NUMBER_OF_STUDENT = [0, 1, 2].map((cur) => ({
  value: cur,
  label: `page.session.text.${cur}_student`
}))
export const LEARN_RESULT = ['LEVEL_UP', 'LEVEL_NO_CHANGE', 'LEVEL_DOWN']
export const SESSION_STATUS = [
  'OPEN_FOLLOW_PLAN',
  'BOOKED',
  'DONE_1',
  'DONE_2',
  'FAILED_STUDENT',
  'FAILED_SYSTEM',
  'FAILED_TEACHER',
  'FAILED_TECHNICAL',
  'FAILED_OTHER'
]
export const SESSION_STATUS_CLASS = [
  'BOOKED',
  'DONE_1',
  'DONE_2',
  'FAILED_TEACHER',
  'FAILED_SYSTEM',
  'FAILED_OTHER',
  'DONE'
]
export const SESSION_REGULAR_CLASS = [
  'WAITING_FOR_START',
  'ON_GOING',
  'PENDING',
  'CANCELED',
  'DONE'
]
export const SESSION_STATUS_STUDENT = [
  'SUCCESS',
  'FAILED_TEACHER',
  'FAILED_TECHNICAL',
  'RESCHEDULED',
  'FAILED_SALE',
  'FAILED_SYSTEM',
  'FAILED_STUDENT'
]
export const SESSION_STATUS_STUDENT_REGULAR = [
  'SUCCESS',
  'RESCHEDULED',
  'FAILED_STUDENT_NO_LEARNED',
  'FAILED_MINISTRY',
  'FAILED_TEACHER',
  'FAILED_TECHNICAL',
  'FAILED_SYSTEM',
  'FAILED_STUDENT'
]
export const STATUS_STUDENT_IN_SESSION = [
  'NOT_START',
  'SUCCESS',
  'FAILED_TEACHER',
  'FAILED_STUDENT',
  'FAILED_TECHNICAL',
  'RESCHEDULED',
  'FAILED_SALE',
  'FAILED_SYSTEM',
  'FAILED_STUDENT_NO_LEARNED',
  'REMOVED_STUDENT'
]
export const SESSION_TEACHER_STATUS = [
  'OPEN',
  'ASSIGNED',
  'CONFIRMED',
  'ASSIGNED_BACKUP',
  'CONFIRMED_BACKUP',
  'WAITING_CANCELLATION_CONFIRMATION',
  'MONITOR_BACKUP',
  'DECLINED',
  'CANCELED'
]
export const ARRANGE_STATUS = [
  'NEW',
  'DONE',
  'STUDYING'
]
export const GENDERS = ['MALE', 'FEMALE']
export const WEEK_DAYS = [
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
  'SUNDAY'
]
export const LEVEL_CLASS = [
  'LEVEL_A',
  'LEVEL_B',
  'LEVEL_C',
  'LEVEL_D',
]
export const WEEK_DAYS_SUNDAY_FIRST = [
  'SUNDAY',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY'
]
export const REASON_ERROR = [
  'SPEAKER_OR_MIC',
  'DEVICE',
  'INTERNET',
  'SYSTEM',
  'DISCONNECT_OR_CAN_NOT_JOIN_CLASS',
  'OTHER'
]
export const TICKET_STATUS_REPORT_RESULT = ['OPEN', 'DONE', 'CANCELED']
export const TICKET_STATUS = ['OPEN', 'PROCESSING', 'DONE', 'CANCELED']
export const TICKET_STATUS_KT = ['OPEN', 'PROCESSING', 'DONE', 'CANCELED']
export const TICKET_STATUS_HTKT = [
  'OPEN',
  'SUCCESS',
  'REFUSED',
  'CAN_NOT_CONTACT',
  'NOT_ANSWER',
  'RESCHEDULED',
  'RESCHEDULED_SAME_DAY',
  'PROCESSING',
  'WRONG_NUMBER',
  'FAILED_STUDENT',
  'CANCELED'
]
export const TICKET_STATUS_MANUAL = [
  'CAN_NOT_CONTACT',
  'NOT_ANSWER',
  'WRONG_NUMBER',
  'PROCESSING',
  'SUCCESS',
  'NOT_SUCCESS'
]
export const TICKET_STATUS_AUTO = [
  'SUCCESS',
  'REFUSED',
  'CAN_NOT_CONTACT',
  'NOT_ANSWER',
  'RESCHEDULED',
  'RESCHEDULED_SAME_DAY',
  'PROCESSING',
  'WRONG_NUMBER',
  'FAILED_STUDENT'
]

export const PACKAGE_CATEGORY = [
  'TUTOR_NEW_3_MONTH',
  'TUTOR_NEW_6_MONTH',
  'TUTOR_NEW_1_YEAR',
  '`TUTOR_NEW_HN_HCM_3_MONTH',
  'TUTOR_NEW_HN_HCM_6_MONTH',
  'TUTOR_NEW_HN_HCM_1_YEAR',
  'TUTOR_EXTEND_PROCESS_6_MONTH',
  'TUTOR_EXTEND_3_MONTH',
  'TUTOR_EXTEND_6_MONTH',
  'TUTOR_EXTEND_1_YEAR'
]
export const CALL_STATUS = [
  'NOT_BEEN_CALL',
  'KNM1',
  'KNM2',
  'KNM3',
  'REFUSE',
  'PROCESS_LATER',
  'APPOINTMENT',
  'SUCCESSFUL_CONTACT'
]
export const CALL_STATUS_RESCHEDULE_DEMO = [
  'KNM1',
  'KNM2',
  'KNM3',
  'REFUSE',
  'APPOINTMENT',
  'FURTHER_ADVICE',
  'SUCCESSFUL_CONTACT'
]
export const CALL_STATUS_CARE_STUDENT = [
  'NOT_BEEN_CALL',
  'KNM1',
  'KNM2',
  'KNM3',
  'REFUSE',
  'APPOINTMENT',
  'SUCCESSFUL_CONTACT'
]

export const CALL_STATUS_STUDENT_CARE_PERIODIC = [
  'NOT_BEEN_CALL',
  'KNM1',
  'KNM2',
  'KNM3',
  'REFUSE',
  'APPOINTMENT',
  'SUCCESSFUL_CONTACT'
]

export const STUDENT_CARE_PERIODIC_METHOD = {
  ANNOUNCE_RESULT: 'ANNOUNCE_RESULT',
  REMIND_CALL: 'REMIND_CALL',
  REMIND_MESSAGE: 'REMIND_MESSAGE'
}

export const CALL_STATUS_TICKET = [
  'KNM',
  'NOT_BEEN_CALL',
  'APPOINTMENT',
  'SUCCESSFULLY',
  'PROCESSING'
]

export const STUDENT_HOMEWORK_STATUS = {
  SUCCESS: 'SUCCESS',
  DONT_SUCCESS: 'DONT_SUCCESS'
}

export const SCHEDULING_STATUS = ['PROCESSING', 'DONE', 'CANCELED', 'OPEN']
export const TICKET_STUDENT_CARE_STATUS = ['TODO', 'OPEN', 'PROCESSING', 'FAILED', 'SUCCESS','CANCELED']
export const TICKET_STUDENT_CARE_STATUS_V2 = ['OPEN', 'PROCESSING', 'SUCCESS','CANCELED']
export const TICKET_STUDENT_CARE_PERIODIC_STATUS = ['OPEN', 'SUCCESS','CANCELED']
export const CARE_RESULT_STATUS = ['SATISFIED', 'PASSABLE', 'UNSATISFIED', 'NONE']
export const STUDY_PROGRAM_CARE_STATUS = ['NOT_ARRANGE', 'NOT_START','STUDYING' ]
export const STUDENT_CARE_TYPE = ['GREETING_CALL', 'TAKE_CARE', 'TAKE_CARE_FIRST_TIME', 'TAKE_CARE_SECOND_TIME']
export const STUDENT_CARE_PERIODIC_TYPE = ['WEEKLY_EXERCISE']
export const OVERDUE_STATUS = ['OVERDUE', 'NOT_OVERDUE']
export const FORM_TAKE_CARE = ['REMIND_MESSAGE', 'REMIND_CALL']
export const PRIORITY_CARE = ['CRITICAL', 'HIGH', 'MEDIUM', 'LOW']

export const IS_ARRANGE_CONDITION = [0, 1]
export const IS_AUTO_ARRANGE_SUCCESS = [0, 1]
export const FAILED_REASONS = [ 
  'NOT_ENOUGH_INFO_TO_ARRANGE',
  'UNEXPIRED_PROGRAM',
  'NOT_HAVE_CLASS_SATISFY_REQUIRED_CONDITION',
  'NOT_HAVE_CLASS_SATISFY_REQUIRED_ARRANGE',
  'NOT_HAVE_TEACHER_SATISFY_REQUIRED_CONDITION',
  'NOT_HAVE_TEACHER_SATISFY_REQUIRED_ARRANGE',
  'NOT_HAVE_START_TIME_SATISFY_REQUIRED_ARRANGE',
  'STUDENT_HAVE_TASK_OF_PROGRAM_TUTOR_REGULAR_OPENING',
  'WAITING_UNTIL_DATE_PROCESSING',
  'OTHER'
]
export const IS_FULL_STUDENT = [0, 1]

export const FAVOR_LEVEL = ['YES', 'NORMAL', 'NO']
export const TALKING_FREQUENCE = [
  'MUCH_TALK',
  'A_LITER_TALK',
  'NORMAL',
  'NO_TALK'
]
export const CONNECTION_STATUS = [
  'OK',
  'NOT_OK_AND_IS_NOT_SUPPORT',
  'NOT_OK_AND_IS_SUPPORT'
]
export const SATISFYING_LEVEL = ['LEVEL_1', 'LEVEL_2', 'LEVEL_3']
export const PRONOUNCE_CORRECTIVE_LEVEL = ['YES', 'SUPPORT_NOT_FULL', 'NO']
export const UNSATISFYING_REASON = [
  'OPERATE',
  'TUTOR',
  'ACADEMIC_PROGRAM',
  'MINISTRY',
  'SALE',
  'SYSTEM',
  'STUDY_TOGETHER',
  'OTHER'
]
export const INTRODUCTION_REASON = ['FOR_SATISFACTION', 'ASK_INFORMATION']
export const TEST_STATUS = ['DONE', 'NOT_TEST', 'TEST_NOT_NOTIFY_PARENT']

export const SPEAKING_TEST_LEVEL = ['LEVEL_A', 'LEVEL_B', 'LEVEL_C']

export const REGULAR_LOG_ACTIONS = [
  'CREATE_CLASS_REGULAR',
  'CANCEL_CLASS_REGULAR',
  'CANCEL_CLASS_REGULAR_STUDENT',
  'SWITCH_CLASS_REGULAR_STUDENT',
  'UPDATE_CLASS_REGULAR',
  'UPDATE_CLASS_REGULAR_STUDENT',
  'UPDATE_STUDENT_REMAIN_SESSION',
  'UPDATE_STUDENT_CLASS'
]

export const REASON_REGISTER_ABSENCE_SESSION = [
  'INTERNET_NOT_CONNECTED',
  'HEALTH',
  'SCHEDULE_LEARN',
  'FUNERAL',
  'WEDDING',
  'TOURISM',
  'TECHNICAL_ERROR',
  'WEATHER',
  'CAR_BREAKDOWN_TRAFFIC_JAM',
  'BIRTH_DAY_PARTY',
  'EXAM_TEST',
  'CLUB',
  'OTHER'
]

export const ABSENCE_PENALTY_TYPE = [
  'NO_PENALTY',
  'PENALTY'
]

export const ABSENCE_PROGRESS_STATUS = [  
  'PROCESSING',
  'PROCESSED',
  'OPEN'
]

export const ABSENCE_TYPE = [
  'LEAVE',
  'UN_LEAVE',
  'SKIP_SHIFT'
]

export const CHANGE_TEACHER_FOR_ABSENCE_STATUS = [
  'CHANGED',
  'RE_CHANGED',
  'NOT_CHANGED'
]

export const CHANGE_TEACHER_STATUS = [
  'CHANGED',
  'NOT_CHANGED'
]

export const CHANGE_TEACHER_QUIT_JOB_STATUS = [
  'CHANGED',
  'NOT_CHANGED',
  'CANCELLED'
]

export const STATUS_REQUEST_ABSENCE_HOMEROOM_CLASS = [
  'WAITING_CONFIRM',
  'CONFIRMED',
  'REJECTED',
  'CANCELED'
]

export const REQUEST_ABSENCE_STATUS = [
  'NEW',
  'ACCEPT',
  'CANCELED',
  'MONITOR_BACKUP',
  'SKIP_SHIFT'
]

export const REQUEST_ABSENCE_RULES = [
  'AFTER_12',
  'BEFORE_12'
]

export const CERTIFICATES = [
  'TOEIC',
  'TOEFL',
  'IELTS',
  'ESOL',
  'TESOL',
  'OTHER'
]
export const WEEK_DAY_INDEXES = {
  MONDAY: 0,
  TUESDAY: 1,
  WEDNESDAY: 2,
  THURSDAY: 3,
  FRIDAY: 4,
  SATURDAY: 5,
  SUNDAY: 6
}
export const WEEK_DAY_ORDER = [
  'SUNDAY',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY'
]

export const REGULAR_CLASS_STATUS = [
  'WAITING_FOR_START',
  'ON_GOING',
  'PENDING',
  'DONE',
  'CANCELED'
]

export const WAITING_CONFIRM_STATUS = [
  'WAITING_SEND_CONFIRM',
  'SENT_CONFIRM',
  'CONFIRMED',
  'EXPIRED',
  'CLASS_TEACHER_CONFIRMED',
  'REJECTED',
  'NOT_HAVE_TEACHER_SATISFY_REQUIRED_ARRANGE'
]

export const USER_ROLES = [
  'admin',
  'manager',
  'employee-ministry',
  'employee-collaborator-class',
  'employee-customer-care',
  'manager-monitor',
  'employee-technical',
  'employee-monitor',
  'manager-teacher',
  'employee-teacher',
  'manager-sale',
  'employee-customer-care-specialist',
  'employee-sale',
  'quality-control',
  'quality-assurance'
]

export const DOCUMENT_LEVEL_OPTIONS = [
  'LEVEL_1',
  'LEVEL_2',
  'LEVEL_3',
  'LEVEL_4',
  'LEVEL_5',
  'LEVEL_6',
  'LEVEL_7'
]

export const USER_ROLES_ENUM = {
  ADMIN: 'admin',
  EMPLOYEE_COLLABORATOR_CLASS: 'employee-collaborator-class',
  EMPLOYEE_CUSTOMER_CARE: 'employee-customer-care',
  EMPLOYEE_MINISTRY: 'employee-ministry',
  EMPLOYEE_MONITOR: 'employee-monitor',
  EMPLOYEE_SALE: 'employee-sale',
  EMPLOYEE_TEACHER: 'employee-teacher',
  EMPLOYEE_TECHNICAL: 'employee-technical',
  MANAGER: 'manager',
  MANAGER_MONITOR: 'manager-monitor',
  MANAGER_SALE: 'manager-sale',
  MANAGER_TEACHER: 'manager-teacher',
  QUALITY_CONTROL: 'quality-control',
  QUALITY_ASSURANCE: 'quality-assurance',
  CUSTOMER_CARE_SPECIALIST: "employee-customer-care-specialist"
}

export const USER_SALE_ENUM = ['employee-sale']
export const SESSION_TYPE = ['DEMO', 'REGULAR', 'BACKUP']
export const SCHEDULE_TYPE = ['REGULAR', 'BACKUP']
export const CLASS_TYPE = ['DEMO', 'REGULAR']
export const CLASS_TYPE_OBJ = {
  REGULAR: 'REGULAR',
  DEMO: 'DEMO'
}
export const SUSPENDED = [0, 1]
export const STATUS_CRM_TRANSFER = [0, 1]

export const GRADE_TEACHER = ['Starters', 'Movers', 'Flyers', 'Ielts']

export const RESERVE_STUDENT_STATUS = ['WAITING_RESERVE', 'IN_RESERVE', 'END_RESERVE']

export const TYPE_QUIT_JOB = ['LEAVE', 'TEMPORARY_SUSPENSION']
export const STATUS_ACCOUNT = ['DEACTIVE', 'ACTIVE']
export const STATUS_QUIT_JOB = [
  'WAITING_CONFIRM',
  'CONFIRMED',
  'REJECTED',
  'CANCELED'
]

export const CONVERSION_STUDENT_STATUS = ['WAITING_FOR_CONVERSION', 'CONVERTED']

export const URL_TEMPLATE = {
  ASSIGN_CLASS_DEMO_LIST: '/assign-class/demo',
  ASSIGN_CLASS_REGULAR_LIST: '/assign-class/regular',
  ASSIGN_CLASS_REGULAR_DETAIL: '/assign-class/regular/schedule',
  ASSIGN_CLASS_MAKEUP_LESSON_LIST: '/assign-class/makeup-lesson',
  ASSIGN_CLASS_MAKEUP_LESSON_DETAIL: '/assign-class/makeup-lesson/schedule',

  CALENDAR_DEMO_LIST: '/calendar/demo',
  CALENDAR_TEACHER_LIST: '/calendar/teacher',
  CALENDAR_TEACHER_BOOK: '/calendar/teacher/book',
  CALENDAR_TEACHER_CANCEL_SCHEDULE: '/calendar/schedule-cancel',
  CALENDAR_TEACHER_BOOK_BACKUP: '/calendar/backup',
  CALENDAR_SUMMARY_DEMO: '/calendar/summary/demo',
  CALENDAR_SUMMARY_REGULAR: '/calendar/summary/regular',
  CALENDAR_TEACHER_CONFIG_TIME_SLOT: '/calendar/config/class-time-slot',
  CALENDAR_TEACHER_CONFIG_FRAME: '/calendar/config/class-schedule',

  CLASS_DEMO_LIST: '/class/demo',
  CLASS_DEMO_CREATE: '/class/demo/create',
  CLASS_DEMO_DETAIL: '/class/demo/edit',
  CLASS_REGULAR_LIST: '/class/regular',
  CLASS_REGULAR_DETAIL: '/class/regular/edit',

  CONFIG_REGION: '/config/region',
  CONFIG_PROVINCE: '/config/province',
  CONFIG_SCHOOL: '/config/school',
  CONFIG_DEVICE: '/config/device',
  CONFIG_GRADE: '/config/grade',
  CONFIG_LEVEL: '/config/level',
  CONFIG_SUBJECT: '/config/subject',
  CONFIG_STUDY_PROGRAM: '/config/study-program',
  CONFIG_LEARNING_MODEL: '/config/crm-learning-model',
  TUTOR_FILE_LIST: '/config/tutor-file',
  CONFIG_TEACHER_MANAGE: '/config/teacher-manage',
  CONFIG_BREACH_RULES: '/config/breach',
  CONFIG_SALARY: '/config/salary',
  CONFIG_MAKE_UP_SESSION_NUMBER: '/config/make-up-session-number',
  CONFIG_TUTOR_TRACKING_METRICS: '/config/tutor-tracking-metrics',
  CONFIG_COMPONENT_SCORE_WEIGHTS: '/config/component-score-weights',
  CONFIG_COMPONENT_FOLDER_CLASSIN: '/config/folder-classin',
  CONFIG_POTENTIAL_POINT: '/config/potential-points',

  LEARN_RESULT_DEMO_LIST: '/learn-result/demo',
  LEARN_RESULT_DEMO_DETAIL: '/learn-result/demo/edit',

  STATISTICS_REPORT: '/statistics-report',
  STATISTICS_REPORT_DETAIL: '/statistics-report/edit',

  MANAGE_ADVANCE: '/manage/advance/classin-cookie',
  MANAGE_ROLE_LIST: '/manage/role',
  MANAGE_ROLE_DETAIL: '/manage/role/edit',
  MANAGE_USER_LIST: '/manage/user',
  MANAGE_USER_CREATE: '/manage/user/create',
  MANAGE_USER_DETAIL: '/manage/user/edit',
  MANAGE_TEACHER_LIST: '/manage/teacher',
  MANAGE_HOMEROOM_TEACHER: '/manage/homeroom-teacher',

  OPERATION_MONITOR_LIST: '/operation/monitor',
  OPERATION_MONITOR_DETAIL: '/operation/monitor/edit',
  OPERATION_TECHNICAL_LIST: '/operation/technical',
  OPERATION_RESCHEDULE_DEMO_LIST: '/operation/reschedule-demo',
  OPERATION_TECHNICAL_DETAIL: '/operation/technical/edit',
  OPERATION_TECHNICAL_CREATE: '/operation/technical/create',

  QUALITY_ASSURANCE_SESSION: '/quality-assurance-session',
  QUALITY_ASSURANCE_SESSION_EDIT: '/quality-assurance-session/edit',
  QUALITY_CONTROL_SESSION: '/quality-control-session',
  QUALITY_CONTROL_SESSION_EDIT: '/quality-control-session/edit',

  SESSION_HOMEWORK_LIST: '/session/homework',
  SESSION_TEACHING_SCHEDULE: '/session/teaching-schedule',
  SESSION_TEACHING_DETAIL: '/session/teaching-schedule/detail',

  SESSION_REGULAR_LIST: '/session/regular',
  SESSION_REGULAR_DETAIL: '/session/regular/edit',
  SESSION_REGULAR_CREATE: '/session/regular/create',
  SESSION_REGULAR_HOMEWORK: '/session/regular/homework',
  SESSION_REGULAR_HOMEWORK_SPEAKING: '/session/regular/homework/essay-exercises',

  WAITING_CONFIRM_LIST: '/waiting-confirm',
  RECEIVE_BACKUP: '/receive-backup',
  LIST_OBSERVE_BACKUP: '/list-observe-backup',
  DUTY_SCHEDULE_BACKUP: '/duty-schedule-backup',

  USER_INFORMATION: '/teacher-salary',
  TUTOR_QUALITY_REPORT: '/tutor-quality-report',

  STUDENT_LIST: '/student',
  STUDENT_DETAIL: '/student/edit',

  STUDENT_LIST_ABOUT_TO_EXPIRE: '/list-student-about-to-expire',
  STUDENT_LIST_ABOUT_TO_EXPIRE_DETAIL: '/list-student-about-to-expire/edit',
  STUDENT_RESERVE_DETAIL: '/student/reserve',
  STUDENT_TRANSFER: '/transfer-student',
  STUDENT_TRANSFER_DETAILS: '/transfer-student/details',

  STUDENT_CARE_FIRST: '/care-student/care-student-first',
  STUDENT_CARE_SECOND: '/care-student/care-student-second',
  STUDENT_CARE_THIRD: '/care-student/care-student-third',
  STUDENT_CARE_TICKET: '/student-care/ticket',
  STUDENT_CARE_PERIODIC_TICKET: '/student-care/periodic/ticket',
  CUSTOMER_SURVEY: '/student-care/customer-survey',
  CUSTOMER_SURVEY_DETAIL: '/student-care/customer-survey/edit',

  REGISTER_ABSENCE: '/absence/register',
  MANAGE_ABSENCE_TEACHER: '/absence/manage-absence-teacher',

  ABSENCE_HOMEROOM_CLASS: '/absence-homeroom-class/register-absence',
  MANAGE_ABSENCE_HOMEROOM_CLASS: '/absence-homeroom-class/manage-cancel',
  
  SUPPORT_QUIT_JOB: '/support/quit-job/support-quit-job',
  MANAGE_SUPPORT_QUIT_JOB: '/support/quit-job/manage-request-quit-job',
  DETAIL_SUPPORT_QUIT_JOB: '/support/quit-job/detail-request-quit-job',
  CANCEL_CLASS_QUIT_JOB: '/support/quit-job/cancel-class-teacher-quit-job',

  STUDENT_PROGRAM_CONVERSION_DETAIL: '/student/program-conversion',

  SYSTEM_HOME: '/',
  SYSTEM_FORBIDDEN: '/403',
  SYSTEM_NOT_FOUND: '/404',
  SYSTEM_ERROR: '/500',
  SYSTEM_DASHBOARD: '/dashboard',
  SYSTEM_LOGIN: '/login',
  SYSTEM_PROFILE: '/profile',
  SYSTEM_USER_INFORMATION: '/user-info'
}

export const REGULAR_TIME_OPTIONS = []

export function addTimeLabelV2 (timeList) {
  return _.uniqBy(timeList, 'classTimeSlotId')?.map((item) => {
    const { startTime, endTime, classTimeSlotId } = item
    const label = [timeConvertHHmm(startTime), timeConvertHHmm(endTime)].join(' - ')
    return {
      label,
      value: classTimeSlotId
    }
  })
}

export function translateConst (t, opts, type) {
  return opts.map((value) => ({
    value,
    label: t(`constant.${type}.${value}`)
  }))
}

export const Operator = {
  AND_MULTIPLE_FIELDS: 'AND_MULTIPLE_FIELDS',
  BETWEEN: 'BETWEEN',
  EQUAL: 'EQUAL',
  EQUAL_OR_NULL: 'EQUAL_OR_NULL',
  EQUAL_CASE_INSENSITIVE: 'EQUAL_CASE_INSENSITIVE',
  GREATER_THAN: 'GREATER_THAN',
  GREATER_THAN_OR_EQUAL: 'GREATER_THAN_OR_EQUAL',
  LESS_THAN: 'LESS_THAN',
  NOT_EQUAL: 'NOT_EQUAL',
  NOT_IN: 'NOT_IN',
  LESS_THAN_OR_EQUAL: 'LESS_THAN_OR_EQUAL',
  LIKE: 'LIKE',
  LIKE_IGNORE_CASE: 'LIKE_IGNORE_CASE',
  IN: 'IN',
  IS_NULL: 'IS_NULL',
  IS_NOT_NULL: 'IS_NOT_NULL',
  OR_MULTIPLE_FIELDS: 'OR_MULTIPLE_FIELDS'
}

export const SortType = {
  ASC: 'ascend',
  DESC: 'descend'
}

export const ActionType = {
  CHARGE: 'CHARGE',
  CHARGE_BUSY: 'CHARGE_BUSY',
  DECREASE: 'DECREASE',
  INCREASE: 'INCREASE',
  INIT: 'INIT',
  REFUND: 'REFUND',
  REFUND_BUSY: 'REFUND_BUSY',
  SET: 'SET',
  CHANGE_PACKAGE: 'CHANGE_PACKAGE',
  SET_MAIN: 'SET_MAIN',
  SET_BUSY: 'SET_BUSY',
  MERGE: 'MERGE'
}

export const ObjectType = {
  SESSION: 'SESSION',
  ORDER: 'ORDER',
  DESTINATION: 'DESTINATION',
  SOURCE: 'SOURCE',
  ADMIN: 'ADMIN'
}

export const HTTP_LINK = {
  RULES_CLASS: 'https://bit.ly/3XQKpVa',
  TUTORIAL_VIDEO: 'https://www.youtube.com/watch?v=DPBbmdPSJsI'
}

export const PHONE_HOTLINE = "0931208686 (nhánh 3)"

export const IMPORT_CUSTOMER_CARE_STUDENT_RESULT_TEMPLATE = 'https://docs.google.com/spreadsheets/d/1ycY75k4VMUI6_yrk9WUsOXdGPZYHTQoFx_5LLB1VCqc/edit#gid=0'

export const linkSeeInstructions = 'https://docs.google.com/spreadsheets/d/1H3MPFGeK76sdfpAs0LUWPenDTR-mAqQwoGikunP39f4/edit#gid=1609548363'

export const CareStudentType = {
  TURN_1: 'TURN_1',
  TURN_2: 'TURN_2',
  TURN_3: 'TURN_3',
}

export const MAX_STUDENT_IN_CLASS_DEMO = 1

export const STUDENT_CARE_TICKET_STATUS = {
  DRAFT: 'DRAFT',
  TODO: 'TODO',
  OPEN: 'OPEN',
  PROCESSING: 'PROCESSING',
  FAILED: 'FAILED',
  SUCCESS: 'SUCCESS',
  CANCELED: 'CANCELED '
}

export const OPINION_SCHEDULE_LIST = [
  "SUCCESS",
  "CHANGE_TUTOR",
  "CHANGE_SCHEDULE",
  "CHANGE_LEVEL_CLASS"
]

export const EXPECT_PARENT_LIST = [
  "EXPECT_0",
  "EXPECT_1",
  "EXPECT_2",
  "EXPECT_3",
  "EXPECT_4",
  "EXPECT_5"
]

export const ABILITY_OF_STUDENT = ['EXCELLENT', 'GOOD', 'RATHER', 'MEDIUM', 'WEAK']
export const ASSIGN_HOMEWORK = ['YES', 'NO']
export const TEACHING_TIME = ['GUARANTEED_TEACHING_TIME_1', 'GUARANTEED_TEACHING_TIME_2', 'GUARANTEED_TEACHING_TIME_3', 'GUARANTEED_TEACHING_TIME_4']
export const INTERACTION = ['LOTS', 'NORMAL', 'LITTLE']
export const PROBLEM_DURINGS_THE_TEACHING_TIME = ['TUTOR', 'TECHNIQUE', 'COORDINATOR', 'CLASSMATE', 'STUDY_PROGRAM', 'STUDENTS_THEMSELVES', 'SALE', 'OTHER']
export const ATTIDUDE_STUDENT = ['POSITIVE_HAPPY', 'NORMAL', 'TIMID_DEPRESSED', 'NAUGHTY_NOT_FOCUSED']
export const DILIGENT = ['ON_TIME', 'LATE']
export const STUDENT_EQUIPMENT = ['STUDENT_TECHNICAL_1', 'STUDENT_TECHNICAL_2', 'STUDENT_TECHNICAL_3', 'STUDENT_TECHNICAL_4']
export const ATTIDUDE_TEACHER = ['ENTHUSIASM', 'NORMAL', 'SUPERFICIAL']
export const TEACHER_MANNERS = ['TEACHER_MANNERS_1', 'TEACHER_MANNERS_2', 'TEACHER_MANNERS_3', 'TEACHER_MANNERS_4']
export const QUALIFICATIONS_TEACHER = ['TEACHER_QUALIFICATION_1', 'TEACHER_QUALIFICATION_2', 'TEACHER_QUALIFICATION_3', 'TEACHER_QUALIFICATION_4']
export const TEACHER_EQUIPMENTS = ['TEACHER_TECHNICAL_1', 'TEACHER_TECHNICAL_2', 'TEACHER_TECHNICAL_3', 'TEACHER_TECHNICAL_4']
export const TEACHING_METHOD = ['POSITIVE', 'NORMAL', 'IRREGULAR', 'NOT_INTERESTED']

export const RESCHEDULE_DEMO_TYPE = {
  SAME_DAY: 'SAME_DAY',
  OTHER_DAY: 'OTHER_DAY'
}
export const eventTeacherDay = {
  startTime: '15/11/2023',
  endTime: '30/11/2023',
  eventLink: 'https://drive.google.com/file/d/1ZAY27krqJgQaytgmaCk3XLkuxc60z28s/view',
  description: `
    Happy Vietnamese Teacher's Day. We would like to celebrate this event with you all. <br />
    Let’s join us in "The new tutor" Gala. Click here for more information.
  `,
}

export const minSessionPerWeek = 2
export const STATUS_QUALITY_CONTROL = {
  DONE: 'DONE',
  TODO: 'TODO'
}

export const TYPE_QUALITY = {
  QUALITY_CONTROL_SYSTEM: 'QUALITY_CONTROL_SYSTEM',
  QUALITY_ASSURANCE: 'QUALITY_ASSURANCE'
}

export const configNumberStudent = 'callto:0'

export const INSTRUCT_LINK = "https://docs.google.com/spreadsheets/d/1VEUCGbVqSxt3Vm_IKdTI4wljuC8GykzXSmVM_CebUW4/edit#gid=0"
export const quarterlyExamSkill = {
  PRONUNCIATION: 'PRONUNCIATION',
  STRUCTURE: 'STRUCTURE',
  VOCAB: 'VOCAB'
}

export const NUMBER_QUESTION = 5

export const evaluateType = {
  NORMAL: "NORMAL",
  QUARTERLY: "QUARTERLY"
}

export const typeQuarterlyExam = {
  NORMAL: 'NORMAL',
  QUARTERLY_EXAM: 'QUARTERLY_EXAM'
}

export const statusQuarterlyExam = {
  DONE: 'DONE',
  TODO: 'TODO',
  CANCELLED: 'CANCELLED'
}

export const CONCLUDE_LEARNING_ATTITUDE = {
  UNACHIEVED: 'UNACHIEVED',
  ACHIEVED: 'ACHIEVED',
  PROGRESSING: 'PROGRESSING',
  CONCLUSION: 'CONCLUSION'
}

export const DILIGENCE_STUDENT_STATUS = {
  UNACHIEVED: 'UNACHIEVED',
  ACHIEVED: 'ACHIEVED',
}

export const CLASS_REGULAR_SESSION_STATUS = {
  DONE: 'DONE', 
  CANCELED: "CANCELED",
  BOOKED: "BOOKED",
  FAILED_OTHER: "BOOKED",
  FAILED_TEACHER: "FAILED_TEACHER",
  FAILED_SYSTEM: "FAILED_SYSTEM"
}

export const EVALUATION_CRITERIA_QUALITY_CONTROL = {
  DAILY_START_ON_TIME : 'DAILY_START_ON_TIME',
  DAILY_STANDARD_CLASS_DURATION : 'DAILY_STANDARD_CLASS_DURATION',
  DAILY_CHOOSES_LEARNING_MATERIAL : 'DAILY_CHOOSES_LEARNING_MATERIAL',
  DAILY_USED_LEARNING_MATERIAL : 'DAILY_USED_LEARNING_MATERIAL',
  DAILY_EMPOWERS_STUDENTS : 'DAILY_EMPOWERS_STUDENTS',
  DAILY_OPEN_CAMERA : 'DAILY_OPEN_CAMERA',
  DAILY_REMINDER_HOMEWORK : 'DAILY_REMINDER_HOMEWORK',
  DAILY_STANDARD_APPEARANCE : 'DAILY_STANDARD_APPEARANCE',
  DAILY_SOUND : 'DAILY_SOUND',
  DAILY_NETWORK_STATUS : 'DAILY_NETWORK_STATUS',
  DAILY_DEVICE_TEACHER_USE : 'DAILY_DEVICE_TEACHER_USE',
}

export const MIN_DATE_EXPIRED = 40;

export const LEARNING_HISTORY = {
  CHANGE_LESSONS: 'CHANGE_LESSONS',
  RESERVE: 'RESERVE',
  CONVERT_CTH: 'CONVERT_CTH'
}

export const CARE_HISTORY = {
  MESSAGE_HISTORY: 'MESSAGE_HISTORY',
  CARE_JOURNEY: 'CARE_JOURNEY',
  EXTENDED_CARE_HISTORY: 'EXTENDED_CARE_HISTORY'
}

export const LEARNING_OUTCOMES_ENUM = {
  RESULT_STUDY: 'RESULT_STUDY',
  LEARNING_ATTITUDE: 'LEARNING_ATTITUDE',
  DILIGENCE: 'DILIGENCE',
}

export const TYPE_CHART_ATTITUDE = {
  ATTITUDE_SESSION: 'ATTITUDE_SESSION',
  ATTITUDE_MONTH: 'ATTITUDE_MONTH'
}

export const LEARN_QUARTER = {
  '1': 'I',
  '2': 'I',
  '3': 'I',
  '4': 'II',
  '5': 'II',
  '6': 'II',
  '7': 'III',
  '8': 'III',
  '9': 'III',
  '10': 'IV',
  '11': 'IV',
  '12': 'IV',
}

export const LINK_REMIND  = `https://educavn-my.sharepoint.com/:w:/g/personal/huonghl_edupia_vn/EQRCw1OGB6JDmeiq-wRAzUcBLR8E-C4BPSFmlh28kzWECg?e=wRVeHE`
export const LINK_REMIND_TUTOR  = `https://educavn-my.sharepoint.com/:x:/g/personal/luongvth_edupia_vn/EZtdE9-8-tRPkL4lnA68-98BFW0tVzHPU7Xl-Ho6ZrTwjg?e=Xcb1X1`
export const LINK_REMIND_MONTHLY_EXAM  = `https://drive.google.com/file/d/1O76MWgUCCD3uz9ifpmPGgjoLs93NeT7y/view`
