import store from 'store'
import { USER_ROLES_ENUM } from './constant'
let globalUser
let isLoggingIn

export function getPermission () {
  return getUser()?.userPermissions || []
}

export function getRefreshToken () {
  return getUser()?.refreshToken
}

export function getRole () {
  return getUser()?.userRoles || []
}

export function getToken () {
  return getUser()?.accessToken
}

export function getUser () {
  if (typeof window === 'undefined') {
    return {
      userRoles: Object.freeze([]),
      userPermissions: Object.freeze([])
    }
  }

  let user = globalUser
  if (!user) {
    try {
      user = JSON.parse(window.sessionStorage.getItem('user') || window.localStorage.getItem('user'))
    } catch (e) {
      console.log(e)
    }
    return user
  }
  if (!user) {
    logout()
    window.open('/login', '_self')
  }
  return user
}

export function getUserName(){
  return getUser()?.userName
}

export function isLoggedIn () {
  if (typeof window === 'undefined') {
    return false
  }
  const isLoggingIn = getLoginState()
  return isLoggingIn && !!getUser()
}

export function logout () {
  globalUser = null
  document.cookie = 'login=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/'
  isLoggingIn = false
  store.remove('user')
}

export function setToken (token) {
  setUserField('accessToken', token.accessToken)
  setUserField('refreshToken', token.refreshToken)
}

export function setUser (user, keepLogin) {
  const { accessToken, refreshToken, userId, userName, userRoles, userPermissions } = user
  globalUser = {
    accessToken,
    refreshToken,
    userId,
    userName,
    userRoles: Object.freeze(userRoles),
    userPermissions: Object.freeze(userPermissions.map(p => p.code))
  }
  storeUser(globalUser)
  setLoginState(keepLogin)
}

function setLoginState (keepLogin) {
  isLoggingIn = true
  let expire = ''
  if (keepLogin && !hasRole(USER_ROLES_ENUM.EMPLOYEE_TEACHER)) {
    expire = '; Expires=Wed, 21 Oct 9999 07:28:00 GMT'
  }
  document.cookie = `login=1${expire}; path=/`
  if (hasRole(USER_ROLES_ENUM.EMPLOYEE_TEACHER)){
    document.cookie = `showModal=1; path=/`
  } 
}

function getLoginState () {
  if (isLoggingIn) return true
  return /(login=1)/.test(document.cookie)
}

export function isShowModalState () {
  return /(showModal=1)/.test(document.cookie)
}

function storeUser (user) {
  store.set('user', user)
}

export function setUserField (field, value) {
  const user = getUser()
  user[field] = value
  storeUser(user)
}

export const hasRole = (roles) => {
  const user = getUser()
  return user?.userRoles?.some((role) =>
    roles.includes(role)
  )
}
export function getUserId () {
  return getUser()?.userId || []
}
