import { addDays, eachDayOfInterval, getDay, getHours, getMinutes, lastDayOfMonth, setHours, setMinutes, subDays } from 'date-fns'
import addMonths from 'date-fns/addMonths'
import addWeeks from 'date-fns/addWeeks'
import endOfDay from 'date-fns/endOfDay'
import endOfMonth from 'date-fns/endOfMonth'
import endOfWeek from 'date-fns/endOfWeek'
import format from 'date-fns/format'
import startOfDay from 'date-fns/startOfDay'
import startOfMonth from 'date-fns/startOfMonth'
import startOfWeek from 'date-fns/startOfWeek'
import _ from 'lodash'
import moment from 'moment'
import { WEEK_DAYS, WEEK_DAYS_SUNDAY_FIRST } from './constant'
import { toString } from 'lodash'

export function currentMomentWithTimezone (timezone = 7) {
  const date = new Date()
  const dateValue = date.getTime()
  const dateWithTimezone =
    dateValue + date.getTimezoneOffset() * 60 * 1000 + timezone * 60 * 60 * 1000
  return moment(dateWithTimezone)
}

export function getNextWeekWithTimzone (index) {
  const current = currentDateWithTimezone()
  const date = addWeeks(current, index)
  const startDay = startOfWeek(date, { weekStartsOn: 1 })
  const endDay = endOfWeek(date, { weekStartsOn: 1 })
  return { startDay, endDay }
}
export function getNextMonthWithTimezone (index) {
  const current = currentDateWithTimezone()
  const date = addMonths(current, index)
  const startDay = startOfMonth(date)
  const endDay = endOfMonth(date)
  return { startDay, endDay }
}

export function currentDateWithTimezone (timezone = 7) {
  const date = new Date()
  const dateValue = date.getTime()
  const timeStampWithTimezone =
  dateValue + date.getTimezoneOffset() * 60 * 1000 + timezone * 60 * 60 * 1000
  return new Date(timeStampWithTimezone)
}

export function currentDateWithTimezoneV2 (dateStart, timezone = 7) {
  const date = new Date(dateStart)
  const dateValue = date.getTime()
  const timeStampWithTimezone =
  dateValue + date.getTimezoneOffset() * 60 * 1000 + timezone * 60 * 60 * 1000
  return new Date(timeStampWithTimezone)
}

export function startAndEndOfDayTimestampWithTimeZone (timestamp, timezone = 7) {
  const clientOffset = new Date().getTimezoneOffset() * 60 * 1000
  const targetTimezoneOffset = -timezone * 60 * 60 * 1000
  const difference = targetTimezoneOffset - clientOffset
  return {
    targetStartTimestamp: startOfDay(timestamp).getTime() + difference,
    targetEndTimestamp: endOfDay(timestamp).getTime() + difference
  }
}

/**
 * Thêm số 0 như là prefix nếu n < 10
 * @param {Number} n
 * @returns {String}
 * VD:
 * 8 --> 08.
 * 11 --> 11.
 */
export const additionalZero = (n) => {
  return n && n > 0 ? (n < 10 ? `0${n}` : n) : '00'
}

/**
 * Đổi date từ data backend trả về sang Date String có dạng dd/MM/yyyy
 * @param {Number} date - Lấy từ data backend server trả về có dạng: YYYYmmdd. VD: 20221225 (25/12/2022)
 * @returns {String} - Date được trả về dưới dạng dd/MM/yyyy. VD: 25/12/2022
 */
export const formatNumberDdMMyyyy = (date) => {
  return date ? format(convertNumberToDate(date), 'dd/MM/yyyy') : ''
}

/**
 * Đổi date từ data backend trả về sang Date Object
 * @param {Number} num - Lấy từ data backend server trả về có dạng: YYYYmmdd. VD: 20221225 (25/12/2022)
 * @returns {Object} - Return về Date Object
 */
export function convertNumberToDate (num) {
  if (!num || num <= 0) {
    return 0
  }
  const day = num % 100
  const month = ((num - day) / 100) % 100
  const year = (num - day - 100 * month) / 10000
  return new Date(year, month - 1, day)
}

/**
 *
 * @param {Object} param0
 * @param {Number} param0.date - Lấy từ data backend server trả về có dạng: YYYYmmdd. VD: 20221225 (25/12/2022)
 * @param {Number} param0.startTime - là thời gian trong ngày hệ quy đổi phút. Ví dụ: startTime = 610 nghĩa là 10 giờ 10 phút sáng
 * @returns ??????????????????????????
 */
export const dateNumberAndStartTimeToDate = ({ date, startTime }) => {
  let dateType = convertNumberToDate(date)
  const { hours, minutes } = timeConvert(startTime)
  dateType = setHours(dateType, hours)
  dateType = setMinutes(dateType, minutes)
  return dateType
}

/**
 *
 * @param {Number} time - là thời gian trong ngày hệ quy đổi phút. Ví dụ: time = 610 nghĩa là 10 giờ 10 phút sáng
 * @returns {String}
 * - Giá trị trả về có dạng: HH:mm. Ví dụ: 09:45
 */
export const timeConvertHHmm = (time) => {
  const { hours, minutes } = timeConvert(time)
  return additionalZero(hours) + ':' + additionalZero(minutes)
}

/**
 *
 * @param {Number} time - là thời gian trong ngày hệ quy đổi phút. Ví dụ: time = 610 là: 10 giờ 10 phút sáng
 * @returns {Object}
 * - Giá trị trả về có dạng: { hours: Number, minutes: Number }
 */
export const timeConvert = (time) => {
  if (!time) return { hours: 0, minutes: 0 }
  return { hours: Math.floor(time / 60), minutes: time % 60 }
}

/**
 * Đổi Date Object thành Date String
 * @param {Object} date - Date object
 * @returns {String} - Ngày trả về được format dạng: YYYYmmdd . VD: 20221225 (25/12/2022)
 */
export const convertDateToNumber = (date) => {
  return format(date, 'yyyyMMdd')
}

/**
 * Lấy ngày-tháng-năm thời điểm hiện tại
 * @returns {String} - Ngày trả về được format dạng: YYYYmmdd . VD: 20221225 (25/12/2022)
 */
export const currentDateNumber = () => convertDateToNumber(new Date())

/**
 *
 * @param {Object[]} rangeTimesList
 * @param {String} rangeTimesList[].id
 * @param {Number} rangeTimesList[].startTime - Thời gian trong ngày của hệ quy đổi phút. Ví dụ: 610 là 10 giờ 10 phút sáng
 * @param {Number} rangeTimesList[].endTime - Thời gian trong ngày của hệ quy đổi phút. Ví dụ: 680 là 11 giờ 20 phút sáng
 * @param {Object} options
 * @param {String} options.optionLabelFormat - Nhận template với 2 key: START, END là key chính. Ví dụ: START_END, START-END. Default: 'START'
 * @param {String} options.optionValueByKey - Key của phần tử trong mảng rangeTimesList được sử dụng làm value của option. Default: 'id'
 * @returns {Array}
 * - Array trả về sẽ là mảng có cấu trúc như sau: [{ value: String | Number, id: Number, label: String }]
 */
export function createRangeTimeOptions (rangeTimesList, options = { optionLabelFormat: 'START', optionValueByKey: 'id' }) {
  if (!rangeTimesList || !rangeTimesList.length) return []
  const { optionLabelFormat, optionValueByKey } = options
  return rangeTimesList.map(time => ({
    value: time[optionValueByKey],
    id: time.id,
    label: optionLabelFormat.replace('START', timeConvertHHmm(time.startTime)).replace('END', timeConvertHHmm(time.endTime))
  }))
}

// TODO: Tuấn Anh check lại xem có cần clone không, đang trùng chức năng với function createRangeTimeOptions
export function createRangeTimeTable (rangeTimesList, options = { optionLabelFormat: 'START', optionValueByKey: 'classTimeSlotId' }) {
  if (!rangeTimesList || !rangeTimesList.length) return []
  const { optionLabelFormat, optionValueByKey } = options
  return rangeTimesList.map(time => ({
    value: time[optionValueByKey],
    label: optionLabelFormat.replace('START', timeConvertHHmm(time.startTime)).replace('END', timeConvertHHmm(time.endTime))
  }))
}

export const numberMoment = (dateNumber) => {
  return !_.isNil(dateNumber) && String(dateNumber).length === 8
    ? moment(convertNumberToDate(dateNumber).toISOString())
    : undefined
}

export function convertDateToTutorDate (date) {
  return Number(format(date, 'yyyyMMdd'))
}

export const getStartDateAndEndDate = (timezone = 7) => {
  const startDate = new Date()
  const endDate = addDays(startDate, 6)
  const startDateWithTimeZone = startDate.getTime() + startDate.getTimezoneOffset() * 60 * 1000 + timezone * 60 * 60 * 1000
  const endDateWithTimeZone = endDate.getTime() + endDate.getTimezoneOffset() * 60 * 1000 + timezone * 60 * 60 * 1000

  return { startDate: moment(startDateWithTimeZone), endDate: moment(endDateWithTimeZone) }
}
export const getEndDateEqualCurrentDate = (timezone = 7) => {
  const endDate = new Date()
  const startDate = subDays(endDate, 6)
  const startDateWithTimeZone = startDate.getTime() + startDate.getTimezoneOffset() * 60 * 1000 + timezone * 60 * 60 * 1000
  const endDateWithTimeZone = endDate.getTime() + endDate.getTimezoneOffset() * 60 * 1000 + timezone * 60 * 60 * 1000

  return { startDate: moment(startDateWithTimeZone), endDate: moment(endDateWithTimeZone) }
}

export const getLstDaysByDayInMonth = (month, day, isManager) => {
  const rs = []

  const dayIdx = WEEK_DAYS_SUNDAY_FIRST.findIndex(x => x === day)

  const startMonth = month
  const endMonth = lastDayOfMonth(month)

  const lstDayInMonth = eachDayOfInterval({ start: startMonth, end: endMonth })

  for (let index = 0; index < lstDayInMonth.length; index++) {
    const element = lstDayInMonth[index]
    const dayElement = getDay(element)
    if (dayElement === dayIdx) {
      rs.push(convertDateToNumber(element))
    }
  }
  return rs
}
export const getDateByDayInWeek = (week, day) => {
  const dayIdx = WEEK_DAYS.findIndex(x => x === day)
  const dateReturn = addDays(week, dayIdx)

  return convertDateToNumber(dateReturn)
}

/**
 * Lấy giờ giấc hiện tại hệ quy đổi phút.
 * @returns {Number}
 * VD: Hiện tại là 9h30 sáng --> 570
 */
export const getTimeNumberCurrent = () => {
  const current = new Date()
  const hours = getHours(current)
  const minutes = getMinutes(current)
  return (hours * 60) + minutes
}

export const dateNumberAndHourMinuteToDate = (param) => {
  let dateType = new Date()
  if (param?.date) {
    dateType = convertNumberToDate(param.date)
  }
  dateType = setHours(dateType, param?.hour || 0)
  dateType = setMinutes(dateType, param?.minute || 0)
  return dateType
}

/**
 * Lấy date từ backend trả về dạng YYYYmmdd
 * @returns {String} - Ngày trả về được format dạng: YYYY-mm-dd . VD: 2023-03-17 (17/03/2023)
 */
export const formatDateToStrikethrough = (date) => {
  const dateString = toString(date)
  const year = dateString.slice(0,4)
  const month = dateString.slice(4,6)
  const day = dateString.slice(6)
  return `${year}-${month}-${day}`
}

/**
 * 
 * @param {Number} value - day value return of moment
 * @returns 
 */
export function getDayOfWeekString(value) {
  switch (value) {
    case 0:
      return 'SUNDAY';
    case 1:
      return 'MONDAY';
    case 2:
      return 'TUESDAY';
    case 3:
      return 'WEDNESDAY';
    case 4:
      return 'THURSDAY';
    case 5:
      return 'FRIDAY';
    case 6:
      return 'SATURDAY';
  }
}

/**
 * 
 * @param {Number} date - định dạnh yyyymmdd
 * @returns {String} - trả về ngày trong tuần vd: MONDAY
 */
export function getWeekDayOfTheDate(date) {
  return getDayOfWeekString(new Date(formatDateToStrikethrough(date)).getDay())
}


/**
 * 
 * @returns {Number} - trả về timestamp miliseconds
 */
export function getCurrentTimeLong() {
  return moment().valueOf();
}

/**
 * 
 * @returns {Number} - trả về HH:mm:ss DD/MM/YYYY 
 */
export function getCurrentTime() {
  return moment().format("HH:mm:ss DD/MM/YYYY");
}

/**
 * @param {Number} time - định dạnh hh:mm
 * @returns {Number} - trả về sô vd 500
 */

export function getTimeNumber(time) {
  if(!time) return null
  const timeConvert = time.split(":")
  return Number(timeConvert[0]*60) + Number(timeConvert[1])
}


export function getStartAndEndDateOfWeek(week, year) {
  // Tạo một đối tượng Date với ngày đầu tiên của năm
  let date = new Date(year, 0, 1);

  // Thêm số ngày (7 * tuần) vào ngày đầu tiên của năm để đến tuần mong muốn
  date.setDate(date.getDate() + (week - 1) * 7);

  // Tìm ngày bắt đầu của tuần (thứ 2)
  let startOfWeek = new Date(date.setDate(date.getDate() - date.getDay() + 1));

  // Tìm ngày kết thúc của tuần (chủ nhật)
  let endOfWeek = new Date(date.setDate(date.getDate() + 6));

  return { startOfWeek, endOfWeek };
}

export function getWeekOptions(year) {
  const options = [];
  const dateOption = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };
  const locale = "vi-VN";
  for (let week = 1; week <= 52; week++) {
    const { startOfWeek, endOfWeek } = getStartAndEndDateOfWeek(week, year);
    const label = `Tuần ${week}: ${startOfWeek.toLocaleDateString(
      locale,
      dateOption
    )} - ${endOfWeek.toLocaleDateString(locale, dateOption)}`;
    options.push({
      value: `${convertDateToTutorDate(startOfWeek)}-${convertDateToTutorDate(
        endOfWeek
      )}`,
      label,
      week: week,
    });
  }
  return options;
}

export function getWeekNumberOptions(year) {
  const weeksInYear = getWeeksInYear(year);
  let weekNumberOptions = [];
  
  for (let week = 1; week <= weeksInYear; week++) {
      weekNumberOptions.push({ value: week, label: `Week ${week}` });
  }

  return weekNumberOptions;
}

function getWeeksInYear(year) {
  const lastDayOfYear = new Date(year, 11, 31);
  const weekNumber = getWeekNumber(lastDayOfYear);
  return weekNumber;
}

function getWeekNumber(date) {
  const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
  const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
  return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
}

/**
 * 
 * @param {Number} dateInt - định dạnh yyyymmdd
 * @returns {Date} - trả về Date()
 */
export function convertDateIntToDate(dateInt) {
  const year = parseInt(dateInt.substring(0, 4), 10);
  const month = parseInt(dateInt.substring(4, 6), 10) - 1; // Month is 0-based
  const day = parseInt(dateInt.substring(6, 8), 10);

  return new Date(year, month, day);
}